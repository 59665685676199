import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";
import { encryptData } from "../DatosEncriptados/EncryptedFunctions";
import AADService from "../funcionesAuth.js";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

const EditarComponenteInfra = (props) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const [validated, setValidated] = useState(false);
  const ubicacion_logica = [
    { id: "1", nombre: "Expuesto a internet" },
    { id: "2", nombre: "Red Confianza" },
    { id: "3", nombre: "Red Interna" },
    { id: "4", nombre: "Segmento controlado" },
  ];
  const relevancia = [
    { id: "1", nombre: "Componente Soporte" },
    {
      id: "2",
      nombre: "Infraestructura crítica transversal/Punto único de falla",
    },
    { id: "3", nombre: "Infraestructura crítica no transversal" },
  ];
  const tipo_ambiente = [
    { id: "1", nombre: "Producción" },
    { id: "2", nombre: "Certificación" },
  ];
 
  const [vRequired, setVRequired] = useState(true);
  const [activo, setActivo] = React.useState([]);
  const [alcance, setAlcance] = useState(0);
  const [idactivo, setIdActivo] = React.useState(null);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  let history = useHistory();

useEffect(() => {

  let idactivo = null;
  if (query.get("idactivo") != null) {
    idactivo = query.get("idactivo");
    setIdActivo(idactivo);
  }
  else {
    idactivo = localStorage.getItem("idactivo");
    setIdActivo(idactivo);
  }

  const obtenerActivo = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "onecomponent/" +
        idactivo +
        "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const encrypteddatActivo = await result.json();
    const decrypteddatActivo = DecryptData(encrypteddatActivo);

    let datActivo = JSON.parse(decrypteddatActivo);
    setActivo(datActivo);
  };
  obtenerActivo();
}, []);

  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      var data = {
        idactivo: parseInt(document.getElementById("IDcomponente").value),
        nombre: document.getElementById("NombreComponente").value,
        descripcion: document.getElementById("Descripcion").value,
        componente_principal: 0,
        ubicacion_logica:
          document.getElementById("Ubicacion_logica") !== null
            ? document.getElementById("Ubicacion_logica").value
            : null,
        relevancia:
          document.getElementById("relevancia") !== null
            ? document.getElementById("relevancia").value
            : null,
        tipo_ambiente:
          document.getElementById("Tipo_Ambiente") !== null
            ? document.getElementById("Tipo_Ambiente").value
            : null,
        ip:
          document.getElementById("IP") !== null
            ? document.getElementById("IP").value
            : "",
        dns:
          document.getElementById("DNS") !== null
            ? document.getElementById("DNS").value
            : "",
        elemento_configuracion:
          document.getElementById("codigo_unico_aplicacion") !== null
            ? document.getElementById("codigo_unico_aplicacion").value
            : "",
        fechacreacion: today.toISOString(),
        idusuariocreacion: localStorage.getItem("idusuario"),
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"), 
      };

      const objectToSend = JSON.stringify({
        ...data,
        ip: encryptData(data.ip),
        dns: encryptData(data.dns),
      });

      fetch(
        process.env.REACT_APP_API_URL +
          "onecomponent/" +
          document.getElementById("IDcomponente").value +
          "/",
        {
          method: "PUT",
          body: objectToSend,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((data) =>
        data.json().then((response) => {
          if (data.status >= 200 && data.status < 300) {
            setEstadoPost(2);
            if (props.location.state === undefined) {
              localStorage.setItem("idactivo", response.idactivo);
              history.push("/EditarComponenteInfra");
            } else {
                localStorage.setItem("idactivo", response.idactivo);
                history.push("/EditarComponenteInfra");
            }
          } else if (data.status >= 500) {
            setEstadoPost(5);
            if (
              data.non_field_errors[0] ===
              "The fields idactivo must make a unique set."
            ) {
              setEstadoPost(6);
            }
          } else if (data.status >= 400 && data.status < 500) {
            setEstadoPost(4);
          }
        })
      )
        .catch(function (err) {});
    }
    setValidated(true);
  };

  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />
    <Row>
        <Col>
          {" "}
          <h2 className='subtitulo'>Editar Componente de Infraestructura</h2>
          <hr />
        </Col>
      </Row>

      <Form
        id='formData'
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label forn-label'>Id Componente Infraestructura</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              disabled
              className='form-control text-center font-weight-bold'
              placeholder={idactivo ? idactivo : "ID Automático"}
              defaultValue={activo.idactivo}
              id='IDcomponente'
            ></input>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>Nombre*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              className='form-control text-center'
              placeholder='Introduzca Nombre'
              defaultValue={activo.nombre}
              required
              id='NombreComponente'
            ></input>
            <Form.Control.Feedback type='invalid'>
              Por favor introduzca un nombre.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>Descripción</label>
          </Col>
          <Col sm={8} xs={10}>
            <textarea
              className='form-control text-center'
              placeholder='Introduzca Descripción'
              defaultValue={activo.descripcion}
              rows='3'
              id='Descripcion'
            ></textarea>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>IP*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              className='form-control text-center'
              placeholder='Introduzca la IP'
              defaultValue={activo.ip}
              id='IP'
              required
            ></input>
            <Form.Control.Feedback type='invalid'>
              Por favor introduzca la IP.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>DNS*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              className='form-control text-center'
              placeholder='Introduzca el DNS'
              defaultValue={activo.dns}
              id='DNS'
              required
            ></input>
            <Form.Control.Feedback type='invalid'>
              Por favor introduzca el DNS.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>
              Código único de aplicación
            </label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              className='form-control text-center'
              placeholder='Introduzca Código único de aplicación'
              defaultValue={activo.elemento_configuracion}
              id='codigo_unico_aplicacion'
            ></input>
            <Form.Control.Feedback type='invalid'>
              Por favor introduzca el Código único de aplicación
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={5}>
            <label className='label forn-label'>
              Ubicación lógica*
            </label>
          </Col>
          <Col sm={3} xs={5}>
            <select
              className='form-control'
              id='Ubicacion_logica'
              required
            >
              <option value={activo.ubicacion_logica}>
                {activo.ubicacion_logica}
              </option>
              {ubicacion_logica.map((campo) => (
                <option key={campo.id} value={campo.nombre}>
                  {campo.nombre}
                </option>
              ))}
            </select>
            <Form.Control.Feedback type='invalid'>
              Por favor seleccione la ubicación lógica.
            </Form.Control.Feedback>
          </Col>
          <Col sm={2} xs={5}>
            <label className='label forn-label'>Tipo Ambiente*</label>
          </Col>
          <Col sm={3} xs={5}>
            <select
              className='form-control'
              id='Tipo_Ambiente'
              required
            >
              <option value={activo.tipo_ambiente}>
                {activo.tipo_ambiente}
              </option>
              {tipo_ambiente.map((campo) => (
                <option key={campo.id} value={campo.nombre}>
                  {campo.nombre}
                </option>
              ))}
            </select>
            <Form.Control.Feedback type='invalid'>
              Por favor seleccione el tipo de ambiente.
            </Form.Control.Feedback>
          </Col>
        </Row>        
        <Row className='mb-3'>       
          <Col sm={4} xs={5} className='text-left'>
            <label className='label forn-label'>Relevancia*</label>
          </Col>
          <Col sm={3} xs={5}>
            <select
              className='form-control'
              id='relevancia'
              required
            >
              <option value={activo.relevancia}>
                {activo.relevancia}
              </option>
              {relevancia.map((campo) => (
                <option key={campo.id} value={campo.nombre}>
                  {campo.nombre}
                </option>
              ))}
            </select>
            <Form.Control.Feedback type='invalid'>
              Por favor seleccione la relevancia.
            </Form.Control.Feedback>
          </Col>
          
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={0}></Col>
          <Col>
            <div className='texto form-text'>* Campos obligatorios</div>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={1}></Col>
          <Col sm={3} xs={3}>
            {" "}
            <Button
              type='submit'
              className='botonPositivo'
              id='send'
            >
              Guardar
            </Button>
          </Col>
          <Col sm={3} xs={3}>
            {" "}
            <Link to='componente-infraestructura'>
              <Button className='botonNegativo'>Descartar</Button>
            </Link>
          </Col>
        </Row>
      </Form>
  </>
  );
};

export default EditarComponenteInfra;
