import { Container, Hidden } from "@mui/material";
import React, { useState, useEffect, Component } from "react";
import Sidebar from "./SideBar";
import {
  Button,
  Navbar,
  Nav,
  NavDropdown,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  HddStack,
  List,
  Grid3x3Gap,
  HddStackFill,
} from "react-bootstrap-icons";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import Menu from "./Menu.js";
import logo from "./main_icon.png";
import AADService from "./funcionesAuth.js";
import { adalApiFetch } from "./adalConfig.js";
import CircularProgress from "@mui/material/CircularProgress";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      correo: "",
      login: false,
      data: [],
    };
    this.onKeyUp = this.onKeyUp.bind(this);
    this.AzureADService = new AADService();
    this.token = this.AzureADService.getToken();
  }
  handleClick() {
    this.setState({ login: true });
  }
  onKeyUp(event) {
    if (event.charCode === 13) {
      this.setState({ login: true });
    }
  }

  salir = () => {
    this.AzureADService.logout();
  };

  // componentDidMount() {
  //   adalApiFetch(fetch, "usuarios/", {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json" ,Authorization: 'Bearer '+ this.token,
  //     },
  //   })
  //     .then((response) => {
  //       response.json().then((responseJson) => {
  //         this.setState({ apiResponse: JSON.stringify(responseJson, null, 2) });
  //       });
  //     })
  //     .catch((error) => {});
  // }

  render() {
    if (this.token) {
      return <Menu login={this.AzureADService.getUser().userName}></Menu>;
    } else {
      return (
        <>
          <Row>
            <Col className='align-items-center'>
              <CircularProgress />
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default App;
