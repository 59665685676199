import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import { without, filter } from "lodash";
import Select from 'react-select';

//Librerias de la tabla
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import AADService from "../funcionesAuth.js";
import CheckRole from "../CheckRole";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import {AdjuntosDecisiones} from "../Administracion/AdjuntosPath.js"

//Finaliza las librerias de la tabla
function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

//Header planes de acción asociados
const headCells = [
  {
    id: "idplanaccion",
    numeric: false,
    disablePadding: true,
    label: "id plan accion",
  },
  {
    id: "nombre",
    numeric: false,
    disablePadding: false,
    label: "plan acción",
  },
  {
    id: "estadopa",
    numeric: false,
    disablePadding: false,
    label: "Estado Plan acción",
  },
];
//////////////////////Tabla dentro del Modal
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding='checkbox'
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className='label'
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    //paddingLeft: theme.spacing(2),iddecision
    //paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));
//////////////////////Fin Tabla dentro del Modal
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          Evaluaciones
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton aria-label='delete' size='large'>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='Filter list'>
          <IconButton aria-label='filter list' size='large'>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "55vh",
    minHeight: "55vh",
  },
}));
//////////////////////Fin Tabla dentro del Modal

//Empieza tabla de activos
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",

    color: "white",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
//Finaliza tabla de activos

const EditarComponente = () => {
  const classes = useStyles();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalDecisionShow, setModalDecisionShow] = React.useState(false);
  const [modalShowProcesos, setModalShowProcesos] = React.useState(false);
  const [decision, setDecision] = React.useState([]);
  const [companiaDat, setCompaniaDat] = React.useState([]);
  const [seguimientoDec, setSeguimientoDec] = React.useState([]);
  const [state, setState] = useState("Activo");
  const [idState, setIdState] = useState(true);
  const [vRequired, setVRequired] = useState(true);
  const [modalInfo, setModalInfo] = React.useState([]);
  const [usuariosDataDecs,setUsuariosDecisiones]= React.useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  //const [nuevoresponsable, setnuevoresponsable] = useState('');
  const [usuariosDatNeg, setUsuariosDatNeg] = React.useState([]);
  const [usuariosDatTI, setUsuariosDatTI] = React.useState([]);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  let history = useHistory();
  const [validated, setValidated] = useState(false);
  const [validatedData, setValidatedData] = useState(false);
  const [selectDecision, setselectDecision] = useState(" ");
  const opcionesDecision = ["Evitar", "Aceptar", "Mitigar", "Pendiente"];
  const [nvCriticidad, setNvCriticidad] = useState("No Clasificado");
  //////////////////////////////datos tablas
  const [rowsc, setRowsC] = React.useState([]);
  const [rowsCompInit, setRowsCompInit] = React.useState([]);
  const [rowsP, setRowsP] = React.useState([]);
  const [rowsProcInit, setRowsProcInit] = React.useState([]);
  const [procesosSelected, setProcesosSelected] = React.useState([]);
  const [componentesSelected, setComponentesSelected] = React.useState([]);
  //////////////////////Constante Datos Tabla Modal
  const [modalPA, setModalPA] = useState([]);
  const [datContenidos, setDatContenidos] = React.useState([]);
  const [PAAsociados, setPAAsociados] = React.useState([]);
  const [valueDecision, setValueDecision] = React.useState([]);
  const check = new CheckRole("/decisiones");
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const [fileExists, setFileExists] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [dataLideres, setDataLideres] = useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const rolUsuario = localStorage.getItem("rolusuario");
  useEffect(() => {
    //////////////////////Datos del JSON

    const planesAccionAsociados = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "planesdeAccion/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datPlanAccion = await result.json();

      setModalPA(datPlanAccion);

      try{
        const resultdata = await fetch(
          process.env.REACT_APP_API_URL +
            "EditarDecisiones_planAccion/" +
            localStorage.getItem("iddecision") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (resultdata.status === 404) {
          console.error("404 Recurso no encontrado");
          return;
        }
      

        let PlanAccionAsociados = await resultdata.json();
        let idplanaccion = 0;
        let tempRowC = [];
        let temp = [];
        PlanAccionAsociados.map((dat) => {
          idplanaccion = dat.idplanaccion;
        });
        setPAAsociados(PlanAccionAsociados);
        let indexRiesgosTrue = [];
        if (PlanAccionAsociados !== null) {
          for (let i = 0; i < PlanAccionAsociados.length; i++) {
            const element = PlanAccionAsociados[i];
            if (PlanAccionAsociados[i].estadoasociacion == 1) {
              indexRiesgosTrue.push(PlanAccionAsociados[i].idplanaccion);
            }
            if (element.estadoasociacion == 1) {
              tempRowC.push(
                datPlanAccion.filter(
                  (dato) => dato.idplanaccion === element.idplanaccion
                )[0]
              );
              temp.push(element.iddecision_planaccion);
            }
          }
        }
        setComponentesSelected(indexRiesgosTrue);
        setRowsC(tempRowC);
      } catch (error){
        console.error("error en resultdata: ", error)
      }
    };

    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);
      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }
      setOpcionesGenerales(opciones);

      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
        console.log("$$$$$$$$");
      }
    };

    const obtenerDecision = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "EditarDecisiones/" +
          localStorage.getItem("iddecision") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let dataDecision = await result.json();

      setRowsCompInit(dataDecision.ComponentesAsociados);
      setNvCriticidad(dataDecision.nivel_criticidad);
      setDecision(dataDecision);
    };
    const obtenerCompañias = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datCompania = await result.json();
      setCompaniaDat(datCompania);
    };
    const obtenerUsuariosNeg = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuariosrol/" +
          localStorage.getItem("idcompania") +
          "/3/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();
      setUsuariosDatNeg(datUsuarios);
    };
    const obtenerUsuariosDecisiones = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuariosroldecisiones/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();

      setUsuariosDecisiones(datUsuarios);
    };
    const obtenerUsuariosTI = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuariosrol/" +
          localStorage.getItem("idcompania") +
          "/5/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();
      setUsuariosDatTI(datUsuarios);
    };
    const obtenerSelectDecision = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "decisiones/" +
          localStorage.getItem("iddecision") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      //   setselectDecision(data);
    };

    const SeguimientoDecision = async () => {
      try {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "seguimientoDecision/" +
            localStorage.getItem("iddecision") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (result.status === 404) {
          console.error("404 Recurso no encontrado");
          return;
        }
        let datasegui = await result.json();
        setSeguimientoDec(datasegui);
      } catch (error){
        console.error("error en seguimiento decision: ", error);
      }
    };
    const handleCheckFile = async () => {
      const url = `${process.env.REACT_APP_API_URL}administrador/adjuntos/Decisiones/${localStorage.getItem("iddecision")}/`;
      try {
          const response = await fetch(url, {
              method: 'GET',
              headers: {
                  'Authorization': "Bearer " + token,
              },
          });
          if (response.ok) {
              setFileExists(true);
              const blob = await response.blob();
              const contentDisposition = response.headers.get('Content-Disposition');
              const fileNameMatch = contentDisposition && contentDisposition.match(/filename="([^"]+)"/);
              const fileName = fileNameMatch ? fileNameMatch[1] : `Decisiones_${localStorage.getItem("iddecision")}`;
              const fileNameEncoded = encodeURIComponent(fileName);
              const downloadUrl = window.URL.createObjectURL(blob);
              setFileUrl(downloadUrl);
              setFileName(fileNameEncoded);
          } else {
              setFileExists(false);
              setFileUrl(null);
              console.error('File not found');
          }
      } catch (error) {
          console.error('Error checking file existence:', error);
      }
  };
  const obtenerLideresEvidencia = async () => {
    const url = `${process.env.REACT_APP_API_URL}general/parametrosGeneralesxGrupoxParametro/Adjuntos/${localStorage.getItem("idcompania")}/`;

    const result = await fetch(url,
        {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        }
    );
    let datLideres = await result.json();

    const mapLideres = datLideres.map((lider) => {
        return {
          vlrmetrica: lider.vlrmetrica,
        }; 
    });

    setDataLideres(mapLideres);
    };

    fetchUsuario();
    obtenerSelectDecision();
    obtenerDecision();
    obtenerUsuariosNeg();
    obtenerUsuariosDecisiones();
    obtenerUsuariosTI();
    obtenerCompañias();
    SeguimientoDecision();
    planesAccionAsociados();
    setValidatedData(false);
    handleCheckFile();
    obtenerLideresEvidencia();
  }, [validatedData]);

  const filteredOptions = usuariosDataDecs.filter((opcion) =>
    opcion.nombre.toLowerCase()
  )
    .map((opcion) => ({
      value: opcion.nombre,
      label: opcion.nombre,
    idposcresp: opcion.idposicion
    }));
  const handleSelectChange = (option) => {
    setSelectedOption(option ? option: '');
  };

  /////// Añadir tags para los componentes asociados
  const removeTag = (i) => {
    const newTags = [...datContenidos];
    newTags.splice(i, 1);
    setDatContenidos(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      e.preventDefault();
      setValidated(false);
      if (
        datContenidos.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      setDatContenidos([...datContenidos, val]);
      let inputTag = document.getElementById("datosSeparados");
      inputTag.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeTag(datContenidos.length - 1);
    }
  };

  const verificarClasi = () => {
    cambiarCriticidad();
    let confi = document.getElementById("Confidencialidad").value;
    let dispo = document.getElementById("Disponibilidad").value;
    let inte = document.getElementById("Integridad").value;

    if (confi !== "" && dispo !== "" && inte !== "") {
      setVRequired(false);
    } else if (confi == "" && dispo == "" && inte == "") {
      setVRequired(false);
    } else {
      setVRequired(true);
    }
  };

  const cambiarCriticidad = () => {
    let confi = document.getElementById("Confidencialidad");
    let dispo = document.getElementById("Disponibilidad");
    let inte = document.getElementById("Integridad");
    let temp = 0;

    if (
      confi[confi.selectedIndex].value == "No Clasificado" ||
      dispo[dispo.selectedIndex].value == "No Clasificado" ||
      inte[inte.selectedIndex].value == "No Clasificado"
    ) {
      temp = NaN;
    } else {
      temp =
        parseFloat(confi[confi.selectedIndex].id) * 0.4 +
        parseFloat(dispo[dispo.selectedIndex].id) * 0.3 +
        parseFloat(inte[inte.selectedIndex].id) * 0.3;
    }

    if (temp <= 3.9) {
      setNvCriticidad("Activo Soporte");
    } else if (temp > 3.9 && temp <= 6.9) {
      setNvCriticidad("Activo estratégico");
    } else if (temp > 6.9) {
      setNvCriticidad("Activo Crítico");
    } else {
      setNvCriticidad("No Clasificado");
    }
  };

  function bCompania(id) {
    for (const clave in companiaDat) {
      if (id == companiaDat[clave]["idcompania"]) {
        return companiaDat[clave]["compania"];
      }
    }
    return "No Existe";
  }

  const handleChangeState = (event) => {
    if (state == "Activo") {
      setState("Inactivo");
      setIdState(false);
    } else {
      setState("Activo");
      setIdState(true);
    }
  };
  const SendSeguimientoDecision = () => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    let decision_to_send = ""
    if(rolUsuario == 3| rolUsuario == 4 |rolUsuario == 5){
      let tipodecision = document.getElementById("tipodecision");
    
      var segdeci = (decision.decision != tipodecision.options[tipodecision.selectedIndex].text)? "Cambio el estado de la decisión de " +
      decision.decision +" a " + tipodecision.options[tipodecision.selectedIndex].text : "";
      //const seguimiento_decisionesh = segresp == "" && segdeci == ""? "No se realizó ningún cambio" : segdeci + "  " + segresp
      decision_to_send = tipodecision.options[tipodecision.selectedIndex].text
    }else{
      decision_to_send = decision.decision
    }
    var segresp = selectedOption ? "Cambio el nombre del responsable de la decisión de " + decision.nombreresponsable +
    " a " + selectedOption.value : "";
    if (segresp == "" && segdeci == ""){
      var seguimiento_decisionesh = "No se realizó ningún cambio"
    }
    else if(segdeci != "" && selectedOption != ""){
      var seguimiento_decisionesh = segdeci + " y " + segresp;
    }
    else{
      var seguimiento_decisionesh = segdeci + "  " + segresp;
    }
    var data = JSON.stringify({
      iddecision: localStorage.getItem("iddecision"),
      seguimiento_decisiones: seguimiento_decisionesh,
      fecha_decision: today.toISOString(),
      decision: decision_to_send,
      //nombreresponsable: selectedOption.value,
    });
    const result = fetch(
      process.env.REACT_APP_API_URL +
        "seguimientoDecision/" +
        localStorage.getItem("iddecision") +
        "/",
      {
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((data) =>
        data.json().then((response) => {
          if (data.status >= 200 && data.status < 300) {
            setEstadoPost(2);
            const SeguimientoDecision = async () => {
              const result = await fetch(
                process.env.REACT_APP_API_URL +
                  "seguimientoDecision/" +
                  localStorage.getItem("iddecision") +
                  "/",
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                  },
                }
              );
              let datasegui = await result.json();
              setSeguimientoDec(datasegui);
            };
            SeguimientoDecision();
          } else if (data.status >= 500) {
            setEstadoPost(5);
            if (
              data.non_field_errors[0] ===
              "The fields idplanaccion must make a unique set."
            ) {
              setEstadoPost(6);
            }
          } else if (data.status >= 400 && data.status < 500) {
            setEstadoPost(4);
          }
        })
      )
      .catch(function (err) {});
      
  };

  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let componentesAsociadoTemp = [];
      let rowscT;
      let rowspT;
      let actualizarT = [];
      let actualizarF = [];
      let todoplanes = PAAsociados;
      let dataplanaccionput = [];
      let nuevos = rowsc.map((e) => {
        return e.idplanaccion;
      });

      if (rowsProcInit != null) {
        rowspT = rowsProcInit.filter((dato) => dato.estadoasociacion == true);
      }
      if (rowsCompInit != null) {
        rowscT = rowsCompInit.filter((dato) => dato.estadoasociacion == true);
      }

      //aca obtenemos los riesgos asociados que hay que pasarle el estado de asociacion a 0
      let riesgoseliminar = todoplanes.filter(
        (planes) => !nuevos.includes(planes.idplanaccion)
      );

      if (riesgoseliminar.length >= 1) {
        for (let i = 0; i < riesgoseliminar.length; i++) {
          dataplanaccionput.push({
            iddecision_planaccion: riesgoseliminar[i].iddecision_planaccion,
            iddecision: decision.iddecision,
            idplanaccion: riesgoseliminar[i].idplanaccion,
            estadoasociacion: 0,
          });
        }
      }
      //aca obtenemos los riesgos asociados que siguen estando igual no hay que cambiarles nada
      let riesgosactivos = todoplanes.filter((planes) =>
        nuevos.includes(planes.idplanaccion)
      );

      if (riesgosactivos.length >= 1) {
        for (let i = 0; i < riesgosactivos.length; i++) {
          if (riesgosactivos[i].estadoasociacion == 0) {
            dataplanaccionput.push({
              iddecision_planaccion: riesgosactivos[i].iddecision_planaccion,
              iddecision: decision.iddecision,
              idplanaccion: riesgosactivos[i].idplanaccion,
              estadoasociacion: 1,
            });
          }
        }
      }

      //aca obtenemos los riesgos asociados que hay que agregar nuevos osea pasar el estado de asociacion a 1
      for (let i = 0; i < todoplanes.length; i++) {
        for (let j = 0; j < nuevos.length; j++) {
          if (nuevos[j] == todoplanes[i].idplanaccion) {
            nuevos.splice(j, 1);
          }
        }
      }

      if (nuevos.length >= 1) {
        for (let i = 0; i < nuevos.length; i++) {
          dataplanaccionput.push({
            idplanaccion: nuevos[i],
            estadoasociacion: 1,
          });
        }
      }

      setSelectedOption("");
      //setValueDecision(document.getElementById("tipodecision").value);
      let decision_to_send = ""
      if(rolUsuario == 3| rolUsuario == 4 |rolUsuario == 5){
        let tipodecision = document.getElementById("tipodecision")
        decision_to_send = tipodecision.options[tipodecision.selectedIndex].text
      }else{
        decision_to_send = decision.decision
      }
      var responsabledecision = selectedOption? selectedOption.idposcresp : decision.idresponsabledecision;
      var data = JSON.stringify({
        idriesgo: decision.idriesgo,
        tomadordecision: decision.tomadordecision,
        idresponsabledecision: responsabledecision,
        fecha_decision: today.toISOString(),
        observaciones: null,
        idusuario_decision: localStorage.getItem("idusuario"),
        fechacreacion: decision.fechacreacion,
        idusuariocreacion: localStorage.getItem("idusuario"),
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        disp_numerico1: 0.0,
        disp_numerico2: 0.0,
        disp_varchar1: null,
        disp_varchar2: null,
        decision: decision_to_send,
       
        
      });
      let dataplanaccion = JSON.stringify(dataplanaccionput);
      fetch(
        process.env.REACT_APP_API_URL +
          "EditarDecisiones/" +
          localStorage.getItem("iddecision") +
          "/",
        {
          method: "PUT",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        },
                      
      )
        .then((data) =>
          data.json().then((response) => {
            if (data.status >= 200 && data.status < 300) {
              setEstadoPost(2);
            } else if (data.status >= 500) {
              setEstadoPost(5);
            } else if (data.status >= 400 && data.status < 500) {
              setEstadoPost(4);
            }
          })
        )
        .catch(function (err) {});

      fetch(
        process.env.REACT_APP_API_URL +
          "EditarDecisiones_planAccion/" +
          localStorage.getItem("iddecision") +
          "/",
        {
          method: "PUT",
          body: dataplanaccion,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((dataplanaccion) =>
          dataplanaccion.json().then((response) => {
            if (dataplanaccion.status >= 200 && dataplanaccion.status < 300) {
              setEstadoPost(2);
            } else if (dataplanaccion.status >= 500) {
              setEstadoPost(5);
            } else if (
              dataplanaccion.status >= 400 &&
              dataplanaccion.status < 500
            ) {
              setEstadoPost(4);
            }
          })
        )
        .catch(function (err) {});
    }
    setValidated(true);
    setValidatedData(true);
    SendSeguimientoDecision();
  };

  const numberFormatter = (data) => {
    let formatData = parseInt(data).toFixed(2);
    formatData = formatData
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1,")
      .slice(0, -1);
    formatData =
      "$" + formatData.split("").reverse().join("").replace(/^[\.]/, "");
    return formatData;
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  ///////////// Planes de acción asociados a al decisión
  function MyVerticallyCenteredModal(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [contTecno, setContTecno] = React.useState(props.componentes);
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalPA.map((n) => n.idplanaccion);
        setContTecno(newSelecteds);
        return;
      }
      setContTecno([]);
    };
    const handleClick = (event, id) => {
      const selectedIndex = contTecno.indexOf(id);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(contTecno, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(contTecno.slice(1));
      } else if (selectedIndex === contTecno.length - 1) {
        newSelected = newSelected.concat(contTecno.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          contTecno.slice(0, selectedIndex),
          contTecno.slice(selectedIndex + 1)
        );
      }
      setContTecno(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => contTecno.indexOf(id) !== -1;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, modalPA.length - page * rowsPerPage);

    
    return (
      <Modal
        {...props}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Añadir Plan de Acción
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Row>
              <Col sm={4} xs={2}>
                <Form className='buscar'>
                  <Form.Control
                    type='text'
                    placeholder='Buscar'
                    onChange={(e) => setqueryText(e.target.value)}
                    style={{ align: "center" }}
                  />
                </Form>
              </Col>
              <Col sm={4} xs={6}>
                <Button
                  className='botonGeneral'
                  style={{ marginTop: "1%", width: "100%" }}
                  onClick={() => completarTabla(contTecno, "detalles")}
                >
                  {" "}
                  Seleccionar{" "}
                </Button>
              </Col>
            </Row>
            <Paper className={classes.paper}>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby='tableTitle'
                  size={dense ? "small" : "medium"}
                  aria-label='enhanced table'
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={contTecno.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalPA.length}
                  />
                  <TableBody>
                    {stableSort(modalPA, getComparator(order, orderBy))
                      .filter((row) =>
                        row.nombre
                          .toLowerCase()
                          .includes(queryText.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idplanaccion);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(event, row.idplanaccion)
                            }
                            role='checkbox'
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idplanaccion}
                            selected={isItemSelected}
                          >
                            <TableCell padding='checkbox'>
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component='th'
                              id={labelId}
                              scope='row'
                              padding='none'
                            >
                              {row.idplanaccion}
                            </TableCell>
                            <TableCell align='left'>{row.nombre}</TableCell>
                            <TableCell align='left'>{row.estadopa}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={modalPA.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label='Vista compacta'
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  //TODO: completar función para encontrar con ID obeto y llenar tabla
  const completarTabla = (obj, id) => {
    let temp = [];

    switch (id) {
      case "detalles":
        setComponentesSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(modalPA.filter((dato) => dato.idplanaccion === obj[i])[0]);
        }
        setRowsC(temp);
        setModalShow(false);
        break;

      default:
        break;
    }
  };
  //Fin del Modal
  const evaluationState = JSON.parse(localStorage.getItem("estado_evaluacion"));
  return (
    <>
      <AdjuntosDecisiones
        show={modalDecisionShow}
        onHide={() => setModalDecisionShow(false)}
        fileExists={fileExists}
        elemento={"Decisiones"}
        idElemento={localStorage.getItem("iddecision")}
        setValidatedData={setValidatedData}
      />
      <AlertDismissibleExample alerta={estadoPOST} />
      <MyVerticallyCenteredModal
        componentes={componentesSelected}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Row>
        <Col>
          {" "}
          <h2 className='subtitulo'>Tomar Decisión</h2>
        </Col>
      </Row>
      <hr />
      <Form
        id='formData'
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Row className='col-sm-6 mb-5'>
            <Col sm={4} xs={6}>
              <label className='label forn-label'>Fecha Decisión</label>
            </Col>
            <Col sm={6} xs={6}>
              <input
                type='text'
                disabled
                className='form-control text-center font-weight-bold'
                defaultValue={decision.fechacreacion}
                style={{ justifyContent: "end" }}
                id='fechacreacion'
              ></input>
            </Col>
          </Row>
          <Row className='col-sm-6 mb-6'>
            <Col sm={3} xs={4}>
              <label className='label forn-label'>Id Decision</label>
            </Col>
            <Col sm={3} xs={3}>
              <input
                type='text'
                disabled
                className='form-control text-center font-weight-bold'
                defaultValue={decision.iddecision}
                id='iddecision'
              ></input>
            </Col>
          </Row>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <Row
            className='col-sm-4 mb-5'
            style={{
              alignItems: "center",
            }}
          >
            <Col sm={5} xs={12}>
              <label className='label forn-label'>Id Riesgo</label>
            </Col>
            <Col sm={7} xs={12}>
              <input
                type='text'
                disabled
                className='form-control text-center font-weight-bold'
                defaultValue={decision.idriesgo}
                id='idriesgo'
                style={{ height: "70px", width: "200px" }}
              ></input>
            </Col>
          </Row>

          <Row
            className='mb-5'
            style={{
              alignItems: "center",
            }}
          >
            <Col sm={5} xs={12}>
              <label className='label form-label'>Riesgo Residual (MM)</label>
            </Col>
            <Col sm={7} xs={12}>
              <input
                className='form-control text-center font-weight-bold'
                disabled
                value={numberWithCommas(
                  parseFloat(decision.exposicionresidual).toFixed(2)
                )}
                id='exposicionresidual'
                style={{ height: "70px", width: "200px" }}
              ></input>
            </Col>
          </Row>
          <Row
            className='col-sm-4 mb-5'
            style={{
              alignItems: "center",
            }}
          >
            <Col sm={5} xs={12}>
              <label className='label form-label'>Nivel Riesgo</label>
            </Col>
            <Col sm={7} xs={12}>
              <input
                className='form-control text-center font-weight-bold'
                disabled
                defaultValue={decision.nivelriesgo}
                id='nivelriesgo'
                style={{ height: "70px", width: "200px" }}
              ></input>
            </Col>
          </Row>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Row
            className='mb-5'
            style={{
              width: "70%",
            }}
          >
            <Col sm={2} xs={12}>
              <label className='label form-label'>Riesgo</label>
            </Col>
            <Col sm={10} xs={12}>
              <input
                type='text'
                disabled
                className='form-control text-center font-weight-bold'
                defaultValue={decision.descripcionriesgo}
                required
                id='descripcionriesgo'
              ></input>
            </Col>
          </Row>
          {(rolUsuario == 3| rolUsuario == 4 |rolUsuario == 5)? (   
            <div
              style={{
                display: "flex",
                width: "30%",
              }}
            >
              <Row className='mb-3 col-9'>
                <Col sm={4} xs={12}>
                  <label className='label forn-label'>Decisión</label>
                </Col>
                <Col sm={6} xs={10}>
                  <select
                    id='tipodecision'
                    style={{
                      height: "40px",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      width: "180px",
                    }}
                  >
                    <option>{decision.decision}</option>
                    {opcionesDecision.map((opcion) =>
                      opcion === decision.decision ? (
                        ""
                      ) : (
                        <option value={opcion}>{opcion}</option>
                      )
                    )}
                  </select>
                  <Form.Control.Feedback type='invalid'>
                    Por favor seleccione una Decisión.
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </div>
          ):
          ("")}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            
          }}
        >
          <Row
            className='col-7'
            style={{
              width: "70%",
              display: "flex",
              justifyContent: "space-between",
              
            }}
          >
            <Col sm={2} xs={12}>
              <label className='label form-label'>Nombre del responsable</label>
            </Col>
            <Col sm={9} xs={12}>
              <input
                type='text'
                disabled
                className='form-control text-center font-weight-bold'
                defaultValue={selectedOption? selectedOption.value : decision.nombreresponsable}
                required
                id='nombreresponsable'
              ></input>
            </Col>
          </Row>
          <Row className='col-5'
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "space-between",
          }}>
            <Col sm={2} xs={12}>
              <label className='label forn-label'>Cambiar responsable</label>
            </Col>
            <Col sm={9} xs={12}>
              <div>
                <Select
                  id='selectrespondecision'
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: "40px",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                    width: "300px"
                  })
                  }}
                  options={filteredOptions}
                value={filteredOptions.find((option) => option.value === selectedOption)}
                  onChange={handleSelectChange}
                />
              </div>
            </Col>
          </Row>
        </div>
      {/* ///////////////// Inicio de Columna de Adjuntos /////////////////// */}
        <div
           style={{
            display: "flex",
            width: "100%",
            padding: "5px",
          }}>
          <Row className="col-12">
            <Col sm={4} xs={12}>
              <label className='label form-label'>Evidencia de la decisión</label>
            </Col>
            <Col mb={4}>
              <Button
                className='botonIngreso'
                onClick={() => {
                  setModalDecisionShow(true);
                  }}
                disabled={!dataLideres.some((lider) => lider.vlrmetrica == localStorage.getItem("idusuario"))}
                  >
                Cargar
              </Button>
            </Col>
            <Col sm={4} xs={12}>
                {fileUrl && (
                    <a href={fileUrl} download={fileName}>
                        {decodeURIComponent(fileName)}
                    </a>
                )}
              </Col>
          </Row>
        </div>
      {/* ///////////////// Fin de Columna de Adjuntos /////////////////// */}

        <div>
          {check.checkPermits(opcionesGenerales, "PUT") /*&& evaluationState*/ ? (
            <Row className='mb-3'>
              <Col sm={2} xs={12}></Col>
              <Col sm={3} xs={3}>
                <Button type='submit' className='botonPositivo' id='send'>
                  Guardar
                </Button>
              </Col>
              <Col sm={3} xs={3}>
                {" "}
                <Link to='decisiones'>
                  <Button className='botonNegativo'>Descartar</Button>
                </Link>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>

        <Row className='mb-3'>
          <Col>
            <br />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md={10}>
            <h3>Planes de acción asociados a la decisión</h3>
          </Col>
          <Col md={2}>
            <Button className='botonIngreso' onClick={() => setModalShow(true)}>
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        <Row className='mb-3'>
          <TableContainer component={Paper} className='table table-bordered'>
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell align='left'>ID Plan acción</StyledTableCell>
                  <StyledTableCell align='left'>
                    Nombre Plan acción
                  </StyledTableCell>
                  <StyledTableCell align='left'>
                    Porcentaje Plan acción
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    Informacion Plan acción
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsc.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component='th' scope='row'>
                      {row.idplanaccion}
                    </StyledTableCell>
                    <StyledTableCell align='left'>{row.nombre}</StyledTableCell>
                    <StyledTableCell align='left'>
                      {row.porcentajeavance == null
                        ? 0
                        : parseFloat(row.porcentajeavance).toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {row.descripcionseguimiento !== null
                        ? row.descripcionseguimiento
                        : row.descripcionpa}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        <Row className='mb-3'>
          <Col md={10}>
            <h3>Seguimiento de la Decisión</h3>
          </Col>
        </Row>
        <Row className='mb-3'>
          <TableContainer component={Paper} className='table table-bordered'>
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell align='left'>ID Decisión</StyledTableCell>
                  <StyledTableCell align='left'>Comentarios</StyledTableCell>
                  <StyledTableCell align='left'>
                    Fecha de la Modificación
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    Decisión Actual
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {seguimientoDec.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component='th' scope='row'>
                      {row.iddecision}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {row.seguimiento_decisiones}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {row.fecha_decision}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {row.decision}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
      </Form>
    </>
  );
};

export default EditarComponente;
