import React, { useEffect, useState } from "react";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import { Button, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import { useQuery } from "react-query";
import { IoMdDownload } from "react-icons/io";
import XLSX from "xlsx";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AADService from "../../funcionesAuth";
const AzureADService = new AADService();
const token = AzureADService.getToken();
const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const fetchdataPlanesxRiesgo = async () => {
  const result = await fetch(
    process.env.REACT_APP_API_URL +
      "descarga/porriesgo/" +
      localStorage.getItem("idcompania") +
      "/",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  let param = await result.json();
  return param;
};

const fetchdataDescargaPlanes = async () => {
  const result = await fetch(
    process.env.REACT_APP_API_URL + "descarga/plandeaccion/",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  let param = await result.json();
  return param;
};

const fetchdataDescargaPlanesxVultec = async () => {
  try {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "descarga/paxvultec/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const param = await result.json();
    return param;
  } catch (error) {}
};

const fetchdataDescargaPlanesxVulNotec = async () => {
  const result = await fetch(
    process.env.REACT_APP_API_URL +
      "descarga/paxvulnotec/" +
      localStorage.getItem("idevaluacion") +
      "/",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  const param = await result.json();
  return param;
};

const fetchdataDescargaSeguixPA = async () => {
  const result = await fetch(
    process.env.REACT_APP_API_URL + "descarga/seguimientoxpa/",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  const param = await result.json();
  return param;
};

const fetchdataDescargaDecisionxRiesgo = async () => {
  const result = await fetch(
    process.env.REACT_APP_API_URL + "descarga/decisionxriesgo/",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  const param = await result.json();
  return param;
};

const fetchdataDescargaDecisionxPA = async () => {
  const result = await fetch(
    process.env.REACT_APP_API_URL + "descarga/decisionxpa/",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  const param = await result.json();
  return param;
};

const ActionPlanTable = ({
  data,
  isInEvaluations,
  opcionesGenerales,
  check,
}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      //margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));


  //Modifica temas predeterminados por Material-Table
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });

  var columns = [
    {
      field: "idplanaccion",
      title: "Id Plan Acción",
    },
    {
      field: "nombre",
      title: "Nombre",
    },
    {
      field: "estadopa",
      title: "Estado",
    },
    {
      field: "responsablepa",
      title: "Responsable",
    },
    {
      field: "analistariesgos",
      title: "Analista de Riesgo",
    },
    {
      field: "fechacompromisoactual",
      title: "Fecha Compromiso Actual",
    },
    {
      field: "fechaseguimiento",
      title: "Ultimo Seguimiento",
    },
    {
      field: "porcentajeavance",
      title: "Porcentaje(%) Avance",
    },
    {
      field: "porcentajeavance",
      title: "Comentarios",
    },
  ];

  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState(null);
  const [filter, setFilter] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);

  const {
    data: planPorRiesgo,
    refetch,
    isError: errorPlanPorRiesgo,
  } = useQuery(["PlanPorRiesgo"], () => fetchdataPlanesxRiesgo(), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: descargaPlanes,
    refetch: refetch1,
    isError: errorDescargaPlanes,
  } = useQuery(["descargaPlanes"], () => fetchdataDescargaPlanes(), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: descargaPlanesxVultec,
    refetch: refetch2,
    isError: errorDescargaPlanesxVultec,
  } = useQuery(
    ["descargaPlanesxVultec"],
    () => fetchdataDescargaPlanesxVultec(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: descargaPlanesxVulNotec,
    refetch: refetch3,
    isError: errorDescargaPlanesxVulNotec,
  } = useQuery(
    ["descargaPlanesxVulNotec"],
    () => fetchdataDescargaPlanesxVulNotec(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: descargaSeguixPA,
    refetch: refetch4,
    isError: errorDescargaSeguixPA,
  } = useQuery(["descargaSeguixPA"], () => fetchdataDescargaSeguixPA(), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: descargaDecisionxRiesgo,
    refetch: refetch5,
    isError: errorDescargaDecisionxRiesgo,
  } = useQuery(
    ["descargaDecisionxRiesgo"],
    () => fetchdataDescargaDecisionxRiesgo(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: descargaDecisionxPA,
    refetch: refetch6,
    isError: errorDescargaDecisionxPA,
  } = useQuery(
    ["descargaDecisionxPA"],
    () => fetchdataDescargaDecisionxPA(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  function add_pa() {
    localStorage.setItem("idplanaccion", 0);
  }

  const handleChange = () => {
    setFilter(!filter);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };

  const editar = (event) => {
    localStorage.setItem("idplanaccion", selected);
  };
  const evaluationState = JSON.parse(localStorage.getItem("estado_evaluacion"));
  const handleDownloadsFetch = () => {
    setOpenAlertModal(!openAlertModal);
    refetch();
    refetch1();
    refetch2();
    refetch3();
    refetch4();
    refetch5();
    refetch6();
  };
  const handleOnExport = () => {
    const newBook = XLSX.utils.book_new(),
      sheets = [
        { data: descargaPlanes, name: "Plan de Acción" },
        { data: planPorRiesgo, name: "PA por Riesgo" },
        { data: descargaPlanesxVultec, name: "PA por Vulne Tecnica" },
        { data: descargaPlanesxVulNotec, name: "PA por Vulne NO Tecnica" },
        { data: descargaSeguixPA, name: "Seguimiento por PA" },
        { data: descargaDecisionxRiesgo, name: "Decision por Riesgo" },
        { data: descargaDecisionxPA, name: "Decision por PA" },
      ];

    sheets.forEach(({ data, name }) => {
      if (!data) return;
      const sheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(newBook, sheet, name);
    });

    XLSX.writeFile(newBook, "Informes de Planes de Acción.xlsx");
    setOpenAlertModal(!openAlertModal);
  };
  /* planPorRiesgo &&
  descargaPlanes &&
  descargaPlanes &&
  descargaPlanesxVultec &&
  descargaPlanesxVulNotec &&
  descargaDecisionxRiesgo &&
  descargaDecisionxPA */
  return (
    <>
      {
        <div className={classes.root}>
          <Row>
            <Col>
              <h2 className='titulo'>Planes de Acción</h2>
            </Col>
          </Row>
          <Paper variant='outlined' className={classes.paper}>
            <MuiThemeProvider theme={theme}>
              <MaterialTable
                columns={columns}
                data={data}
                icons={tableIcons}
                options={{
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      ButtonEdit == true &&
                      selectedRow === rowData.tableData.id &&
                      "#fff8d3",
                  }),
                  showTitle: false,
                  filtering: filter,
                  searchFieldAlignment: "left",
                  searchFieldStyle: {}, //Llenar estilo de barra de busqueda
                  headerStyle: {
                    backgroundColor: "#2c2a29",
                    color: "#ffffff",
                    position: "sticky",
                    top: 0,
                    fontFamily: "CIBFont Sans Regular",
                    fontWeight: 400,
                    fontSize: "1.2rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    flex: "1 1 100%",
                  },
                  maxBodyHeight: "650px",
                  selection: false,
                  disableMultipleSelection: true,
                  pageSizeOptions: [10, 20, 30],
                  showSelectAllCheckbox: false,
                  showTextRowsSelected: false,
                  pageSize: 10,
                }}
                components={{
                  Pagination: (props) => (
                    <div className={classes.pagination}>
                      <FormControlLabel
                        className={classes.switch}
                        control={
                          <Checkbox
                            checked={filter}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          <Typography variant='body2'>
                            Mostrar filtros por columnas
                          </Typography>
                        }
                      />
                      <TablePagination
                        {...props}
                        className={classes.pagination}
                      />
                    </div>
                  ),
                }}
                onRowClick={(evt, selectedRow) => {
                  handleClick(selectedRow.idplanaccion);
                  return setSelectedRow(selectedRow.tableData.id);
                }}
                actions={[
                  {
                    icon: () => (
                      <Col>
                        <>
                          {
                            <Button
                              className='botonPositivo'
                              style={{ minWidth: "250px" }}
                              onClick={() => handleDownloadsFetch()}
                            >
                              Descarga de Planes de Acción &nbsp;
                              <IoMdDownload />
                            </Button>
                          }
                        </>
                      </Col>
                    ),
                    position: "toolbar",
                  },
                  {
                    icon: () =>
                      opcionesGenerales && (
                        <Link to='NuevoPlanAccion'>
                          {check.checkPermits(opcionesGenerales, "POST") ? (
                            <Button
                              className='botonPositivo'
                              onClick={() => add_pa()}
                              style={{ minWidth: "250px" }}
                            >
                              {" "}
                              Nuevo{" "}
                            </Button>
                          ) : (
                            ""
                          )}
                        </Link>
                      ),
                    onClick: add_pa(),
                    position: "toolbar",
                    isFreeAction: true,
                  },
                  {
                    icon: () => {
                      if (isInEvaluations) {
                        return (
                          evaluationState && (
                            <Link to='EditarPlanAccion'>
                              <Button
                                variant='warning'
                                className='botonGeneral'
                                style={{
                                  minWidth: "250px",
                                  display: ButtonEdit ? "inline" : "none",
                                }}
                                onClick={(event) => editar(event)}
                              >
                                Editar
                              </Button>
                            </Link>
                          )
                        );
                      }
                      if (isInEvaluations === false) {
                        return (
                          <Link to='EditarPlanAccion'>
                            <Button
                              variant='warning'
                              className='botonGeneral'
                              style={{
                                minWidth: "250px",
                                display: ButtonEdit ? "inline" : "none",
                              }}
                              onClick={(event) => editar(event)}
                            >
                              Editar
                            </Button>
                          </Link>
                        );
                      }
                    },
                    onClick: () => editar(),
                    position: "toolbar",
                    isFreeAction: true,
                    hidden: ButtonEdit == false,
                  },
                ]}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                  },
                  pagination: {
                    labelRowsSelect: "Filas",
                    firstTooltip: "Primera página",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Última página",
                  },
                }}
              />
            </MuiThemeProvider>
          </Paper>
        </div>
      }
      {openAlertModal && (
        <Dialog open={openAlertModal} sx={{ borderRadius: "10px" }}>
          <DialogTitle>Alerta</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Este proceso puede demorarse unos segundos, seguro que desea
              descargar ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: "#00c389",
                color: "#fff",
                width: "100px",
                borderRadius: "10px",
                height: "35px",
              }}
              onClick={() => setOpenAlertModal(!openAlertModal)}
            >
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: "#00c389",
                color: "#fff",
                width: "100px",
                borderRadius: "10px",
                height: "35px",
              }}
              onClick={() => handleOnExport()}
            >
              Descargar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ActionPlanTable;
