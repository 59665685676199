import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../CheckRole";
import AADService from "../funcionesAuth.js";
import { getCheckboxUtilityClass } from "@mui/material";
import * as XLSX from "xlsx";
import Loader from "react-loader-spinner";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";

function AlertDismissibleExampleModal({ alertaModal }) {
  switch (alertaModal) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return (
        <Alert variant='success'>
          Cargó y Guardó exitosamente los archivos
        </Alert>
      );
      break;
    case 3:
      return (
        <Alert variant='warning'>No existe información de Frecuencias.</Alert>
      );
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Error en la categorización de las vulnerabilidades
        </Alert>
      );
      break;
    default:
      return null;
      break;
  }
}

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function FrecuenciasSOC() {
  //Establece estilos de componentes
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      //margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      color: "white",
      backgroundColor: "#2c2a29",      
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  //Modifica temas predeterminados por Material-Table
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });

  var columns = [
    {
      field: "idevento_cibernetico",
      title: "Id Evento",
      align: "justify",
    },
    {
      field: "idcompania",
      title: "Compañia",
    },
    {
      field: "ip",
      title: "IP",
    },
    {
      field: "origenalerta",
      title: "Origen Alerta",
    },
    {
      field: "resolucioninterna",
      title: "Resolucion Interna",
    },
    {
      field: "idpropio",
      title: "Id Propio",
    },
    {
      field: "fecha",
      title: "Fecha",
    },
    {
      field: "plataforma_componente",
      title: "Plataforma Componente",
    },
    {
      field: "tipocasouso",
      title: "Tipo Caso Uso",
    },
    {
      field: "idtipoincidentecibernetico",
      title: "Id Tipo Incidente",
    },
  ];

  const classes = useStyles();
  const [eventos, setEventos] = useState([]); //table data
  const [filter, setFilter] = useState(false);
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const check = new CheckRole("/dominio-de-informacion");

  useEffect(() => {
    const fetchdata = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "administrador/eventosCiberneticos/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setEventos(data);
    };
    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);

      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }
      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
        fetchdata();
      }
    };
    fetchUsuario();
  }, []);

  //Habilita y desabilita los filtros por columnas
  const handleChange = () => {
    setFilter(!filter);
  };

  function ModalFrecuencias(props) {
    const [archivo, setArchivo] = React.useState([]);
    const [stateArchivo, setStateArchivo] = React.useState([]);
    const [names, setNames] = React.useState([]);
    const [namesN, setNamesN] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [progress, setProgress] = React.useState(false);

    const timeElapsed = Date.now();
    ////const AzureADService = new AADService();
    ////const token = AzureADService.getToken();
    const today = new Date(timeElapsed);
    let tempArchivos = [];
    let temp = [];
    let temp2 = [];

    const subirArchivo = (e) => {
      setEstadoPost(0);
      setArchivo([]);
      setNames([]);
      setStateArchivo([]);
      setNames([]);
      setNamesN([]);

      for (let i = 0; i < e.length; i++) {
        if (
          e[i].name.split(".")[1] == "xlsx" ||
          e[i].name.split(".")[1] == "csv"
        ) {
          temp.push(e[i].name);
          tempArchivos.push(e[i]);
        }
      }
      setArchivo(tempArchivos);
      setNames(temp);
    };

    const postArchivo = async (event, file) => {
      setEstadoPost(0);
      event.preventDefault();
      const form = event.currentTarget;
      let reader = new FileReader();
      reader.readAsArrayBuffer(file[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        workbook.SheetNames.forEach(function (sheetName) {
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          setLoading(true);
          setProgress(true);
          procesarDatos(names[0], XL_row_object);
        });
      };
    };

    const procesarDatos = async (archivosKEY, info) => {
      var data = {
        idcompania:
          localStorage.getItem("idcompania") != null &&
          localStorage.getItem("idcompania") != undefined
            ? localStorage.getItem("idcompania")
            : 1,
        key: archivosKEY,
        message: info,
      };
      let datos = JSON.stringify(data);
      fetch(
        process.env.REACT_APP_API_URL +
          "administrador/eventosCiberneticos/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "POST",
          body: datos,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            //Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) =>
          response.json().then((data) => {
          
            if (response.status >= 200 && response.status < 300) {
              setEstadoPost(2);
              const fetchdataPost = async () => {
                const result = await fetch(
                  process.env.REACT_APP_API_URL +
                    "administrador/eventosCiberneticos/" +
                    localStorage.getItem("idcompania") +
                    "/",
                  {
                    method: "GET",
                    headers: {
                      Accept: "application/json",
                      //Authorization: "Bearer " + token,
                    },
                  }
                );
                let data = await result.json();
                setEventos(data);
              };
              fetchdataPost();
              //props.onHide();
            } else if (response.status >= 500) {
              setEstadoPost(6);
              //props.onHide();
            } else if (response.status >= 400 && response.status < 500) {
              setEstadoPost(5);
              //props.onHide();
            }
          })
        )
        .catch((err) => console.log(err));
      setShowModal(false);
      setLoading(false);
    };

    if (loading) {
      return (
        <Modal
          {...props}
          size='xl'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          backdrop='static'
          data-keyboard='false'
        >
          <Form onSubmit={(e) => postArchivo(e, archivo)}>
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                Cargar archivos
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AlertDismissibleExampleModal alertaModal={estadoPOST} />
              {/* <Loader type="Circles" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/>
            <Loader type="TailSpin" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/> */}
              <Loader
                type='Oval'
                color='#FFBF00'
                style={{ textAlign: "center", position: "static" }}
              />
              {/* 
            <Loader type="Puff" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/>
            <Loader type="Audio" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/> */}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Form>
        </Modal>
      );
    } else if (!loading) {
      return (
        <Modal
          {...props}
          size='xl'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          backdrop='static'
        >
          <Form onSubmit={(e) => postArchivo(e, archivo)}>
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                Cargar de Eventos
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AlertDismissibleExampleModal alertaModal={estadoPOST} />
              <Row className='mb-3'>
                <Col>
                  <input
                    type='file'
                    name='files'
                    accept='.xlsx,.csv'
                    multiple
                    onChange={(e) => subirArchivo(e.target.files)}
                  ></input>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <ul className='list-group'>
                    <li className='list-group-item'>
                      <Row>
                        <Col sm={4}>
                          <h5>Nombre del archivo</h5>
                        </Col>
                        <Col sm={4}>
                          <h5>Nombre en el servidor</h5>
                        </Col>
                        <Col sm={1}>
                          <h5>Estado</h5>
                        </Col>
                      </Row>
                    </li>
                    {names.map((item, index) => (
                      <li className='list-group-item' key={index}>
                        <Row>
                          <Col sm={4}>{item}</Col>
                          <Col sm={4}>{names[index]}</Col>
                          <Col sm={1}>{stateArchivo[index]}</Col>
                        </Row>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {progress && <CircularProgress />}
              <Button
                type='submit'
                className='botonPositivo'
                style={{ minWidth: "20%" }}
              >
                Cargar
              </Button>
              <Button onClick={props.onHide} className='botonNegativo'>
                Cancelar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      );
    }
  }

  return (
    <div className={classes.root}>
      <ModalFrecuencias show={showModal} onHide={() => setShowModal(false)} />
      <Row>
        <Col>
          <h2 className='titulo' sm={8} xs={11}>
            Eventos Ciberneticos
          </h2>
        </Col>
        <Col style={{ paddingTop: "0.3%" }} sm={3} xs={6}>
          <Button
            className='botonPositivo'
            onClick={() => {
              setShowModal(true);
            }}
          >
            {" "}
            Ingreso de Eventos{" "}
          </Button>
        </Col>
        <Col sm={1} xs={1} />
      </Row>
      <Paper variant='outlined' className={classes.paper}>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            columns={columns}
            data={eventos}
            icons={tableIcons}
            options={{
              showTitle: false,
              filtering: filter,
              searchFieldAlignment: "left",
              searchFieldStyle: { height: 50 },
              headerStyle: {
                backgroundColor: "#2c2a29",
                color: "#ffffff",
                position: "sticky",
                top: 0,
                fontFamily: "CIBFont Sans Regular",
                fontWeight: 400,
                fontSize: "1.2rem",
                lineHeight: 1.6,
                letterSpacing: "0.0075em",
                flex: "1 1 100%",
              },
              maxBodyHeight: "650px",
              selection: false,
              disableMultipleSelection: true,
              pageSizeOptions: [10, 20, 30],
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 10,
            }}
            components={{
              Pagination: (props) => (
                <div className={classes.pagination}>
                  <FormControlLabel
                    className={classes.switch}
                    control={
                      <Checkbox
                        checked={filter}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={
                      <Typography variant='body2'>
                        Mostrar filtros por columnas
                      </Typography>
                    }
                  />
                  <TablePagination {...props} className={classes.pagination} />
                </div>
              ),
            }}
            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No se encontraron registros",
              },
              pagination: {
                labelRowsSelect: "Filas",
                firstTooltip: "Primera página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Última página",
              },
            }}
          />
        </MuiThemeProvider>
      </Paper>
    </div>
  );
}

export default FrecuenciasSOC;
