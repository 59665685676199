import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { forwardRef } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import MaterialTable from "material-table";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilterList from "@mui/icons-material/FilterList";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../../CheckRole";
import Select from "react-select";
//import ExportExcel from "react-export-excel";
import CircularProgress from "@mui/material/CircularProgress";
import AADService from "../../funcionesAuth.js";
import { IoMdDownload } from "react-icons/io";
import XLSX from "xlsx";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return (
        <Alert variant='success'>
          Cerradas correctamente las vulnerabilidades
        </Alert>
      );
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

//const ExcelFile = ExportExcel.ExcelFile;
//const ExcelSheet = ExportExcel.ExcelSheet;
//const ExcelColum = ExportExcel.ExcelColum;

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Eliminar_vul_tec() {
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      //margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });

  var columns = [
    {
      field: "idvulnerabilidad_tecnica",
      title: "Id Vulnerabilidad",
    },
    {
      field: "fuente",
      title: "Fuente de identificación",
    },
    {
      field: "nombrecomponente",
      title: "Nombre del componente",
    },
    {
      field: "ip",
      title: "Dirección IP",
    },
    {
      field: "detallevulnerabilidad",
      title: "Detalle de la Vulnerabilidad",
    },
    {
      field: "fuentecierre",
      title: "Motivo de cierre",
    },
    {
      field: "estadovulnerabilidad",
      title: "Estado de la Vulnerabilidad",
    },
  ];

  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]); //table data
  const [dataDescarga, setDataDescarga] = useState([]);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [filter, setFilter] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const check = new CheckRole("/evaluacion-eliminar-vulnerabilidades-tecnicas");
  let dataput = [];
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [loadingCarge, setLoadingCarge] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const opcionesCierre = [
    { value: "Error en la carga", label: "Error en la carga" },
    { value: "Cerrado por PA", label: "Cerrado por PA" },
    { value: "Re-escaneo", label: "Re-escaneo" },
  ];

  const stylesSelect = {
    control: (base) => ({
      ...base,
      fontFamily: "Roboto",
    }),
    menu: (base) => ({
      ...base,
      fontFamily: "CIBFont Sans Regular",
    }),

    option: (provided, state) => ({
      ...provided,
      //color: state.isFocused ? 'white' : '#495057',
    }),
  };

  function ModalSelect(props) {
    const [valueCierre, setValueCierre] = React.useState([null]);

    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Seleccione el motivo de cierre de las Vulnerabilidades
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ padding: "10px", justifyContent: "center" }}>
            <Col sm={8} xs={10}>
              <Select
                id='valueoption'
                placeholder={"Seleccione una opción del motivo del cierre..."}
                styles={stylesSelect}
                options={opcionesCierre}
                required={"campo obligatorio"}
                onChange={(option) => {
                  setValueCierre(option.label);
                }}
              />
            </Col>
          </Row>
          <Row className='mb-3' style={{ padding: "10px" }}>
            <Col md={2}>
              <Button
                className='botonIngreso'
                type='button'
                onClick={() => DeleteVul(valueCierre, selected)}
              >
                Cerrar
              </Button>{" "}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  useEffect(() => {
    const fetchdata = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "vulnerabilidad/tecnica/cierre" +
          "/" +
          localStorage.getItem("idevaluacion") +
          "/",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let param = await result.json();
      if (result.status >= 200 && result.status < 300) {
        setData(param);
        setLoading(false);
        setError(true);
      } else {
        setData([]);
        setLoading(false);
        setError(false);
      }
    };
    fetchdata();

    const fetchdataDescarga = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/vultec/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let paramdescarga = await result.json();
      if (result.status >= 200 && result.status < 300) {
        setDataDescarga(paramdescarga);
        setLoading(false);
        setError(true);
      } else {
        setDataDescarga([]);
        setLoading(false);
        setError(false);
      }
    };
    fetchdataDescarga();
  }, []);

  const handleClickSelect = (ids) => {
    setSelected(ids);
    setModalShow(true);
  };

  const DeleteVul = (valueCierre, ids) => {
    setModalShow(false);
    setLoadingCarge(true);

    if (ids !== null) {
      ids.map((id) => {
        dataput.push({
          estadovulnerabilidad: id.estadovulnerabilidad,
          fechaedicion: today.toISOString(),
          idevaluacion: id.idevaluacion,
          idvulnerabilidad_tecnica: id.idvulnerabilidad_tecnica,
          idusuariomodificacion: localStorage.getItem("idusuario"),
          motivocierre: valueCierre,
        });
      });
    }
    var data = JSON.stringify(dataput);
    fetch(
      process.env.REACT_APP_API_URL +
        "vulnerabilidad/tecnica/cierre" +
        "/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((data) =>
      data.json().then((response) => {
        if (data.status >= 200 && data.status < 300) {
          setLoadingCarge(false);
          setEstadoPost(2);
          const fetchdatasuccess = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "vulnerabilidad/tecnica/cierre" +
                "/" +
                localStorage.getItem("idevaluacion") +
                "/",

              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let param = await result.json();
            if (result.status >= 200 && result.status < 300) {
              setLoadingCarge(false);
              setData(param);
              setLoading(false);
              setError(true);
            } else {
              setData([]);
              setLoadingCarge(false);
              setLoading(false);
              setError(true);
            }
          };
          const fetchdataDescargasuccess = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "evaluacion/vultec/" +
                localStorage.getItem("idevaluacion") +
                "/",
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let paramdescarga = await result.json();
            if (result.status >= 200 && result.status < 300) {
              setDataDescarga(paramdescarga);
              setLoading(false);
              setError(true);
            } else {
              setDataDescarga([]);
              setLoading(false);
              setError(false);
            }
          };
          fetchdataDescargasuccess();
          fetchdatasuccess();
        } else if (data.status >= 500) {
          setEstadoPost(5);
          setLoadingCarge(false);
        } else if (data.status >= 400 && data.status < 500) {
          setEstadoPost(4);
          setLoadingCarge(false);
        }
      })
    );
    dataput = [];
  };

  const handleChange = () => {
    setFilter(!filter);
  };
  const handleOnExport = () => {
    const newBook = XLSX.utils.book_new(),
      sheet1 = XLSX.utils.json_to_sheet(dataDescarga);
    XLSX.utils.book_append_sheet(newBook, sheet1, "Descarga");
    XLSX.writeFile(newBook, "Descarga Vulnerabilidades Técnicas.xlsx");
  };
  return (
    <div className={classes.root}>
      <AlertDismissibleExample alerta={estadoPOST} />
      <ModalSelect show={modalShow} onHide={() => setModalShow(false)} />
      <Row>
        <Col md={8}>
          <h2 className='titulo'>Cierre de Vulnerabilidades Técnicas</h2>
        </Col>
      </Row>
      {!loadingCarge ? (
        <>
          <Col
            style={{ textAlign: "end", marginBottom: "5px", padding: "0px" }}
          >
            {error ? (
              <>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button className='botonIngreso' onClick={handleOnExport}>
                    Descargar Vulnerabilidades Técnicas &nbsp;
                    <IoMdDownload />
                  </Button>
                )}
              </>
            ) : (
              ""
            )}
          </Col>
          <Paper variant='outlined' className={classes.paper}>
            <MuiThemeProvider style={theme}>
              <MaterialTable
                columns={columns}
                data={data}
                icons={tableIcons}
                options={{
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      ButtonEdit == true && selectedRow === rowData.tableData.id
                        ? "#fff8d3"
                        : "#FFF",
                  }),
                  showTitle: false,
                  filtering: filter,
                  searchFieldAlignment: "left",
                  searchFieldStyle: {}, //Llenar estilo de barra de busqueda
                  headerStyle: {
                    backgroundColor: "#2c2a29",
                    color: "#ffffff",
                    position: "sticky",
                    top: 0,
                    fontFamily: "CIBFont Sans Regular",
                    fontWeight: 400,
                    fontSize: "1.2rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    flex: "1 1 100%",
                  },
                  maxBodyHeight: "650px",
                  selection: true,
                  disableMultipleSelection: true,
                  pageSizeOptions: [10, 20, 30],
                  showSelectAllCheckbox: true,
                  showTextRowsSelected: false,
                  pageSize: 10,
                }}
                components={{
                  Pagination: (props) => (
                    <div className={classes.pagination}>
                      <FormControlLabel
                        className={classes.switch}
                        control={
                          <Checkbox
                            color='primary'
                            checked={filter}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          <Typography variant='body2'>
                            Mostrar filtros por columnas
                          </Typography>
                        }
                      />
                      <TablePagination
                        {...props}
                        className={classes.pagination}
                      />
                    </div>
                  ),
                }}
                actions={[
                  {
                    icon: () => (
                      <Button className='botonPositivo'>
                        Marcar Como Cerrada
                      </Button>
                    ),
                    onClick: (evt, selectedRow) => {
                      handleClickSelect(selectedRow);
                    },
                  },
                ]}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                  },
                  pagination: {
                    labelRowsSelect: "Filas",
                    firstTooltip: "Primera página",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Última página",
                  },
                }}
              />
            </MuiThemeProvider>
          </Paper>
        </>
      ) : (
        <>
          <Loader
            type='Puff'
            color='#FFBF00'
            style={{ textAlign: "center", position: "static" }}
          />
          <Row className='mb-3'>
            <Col className='text-center' sm={12} xs={12}>
              <label className='form-label'>
                Marcando como cerrada las vulnerabilidades, por favor espere.
              </label>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default Eliminar_vul_tec;
