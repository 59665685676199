import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";
import decode from 'jwt-decode';
export const adalConfig = {
  tenant: "b5e244bd-c492-495b-8b10-61bfd453e423", //FRONT
  clientId: "698f6d00-7a7d-48a5-a96f-38c2c3a5e8bf", //FRONT
  endpoints: {    
    api:'698f6d00-7a7d-48a5-a96f-38c2c3a5e8bf'  //CLIENTID BACK
  },  
  'apiUrl': 'https://grc-rotic-alb.apps.bancolombia.com',
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
export const obtainTimeLeft = () => {
  const token = getToken();
  if (!token) {
    console.log("No hay token");
    return;
  }
  const tokenDecoded = decode(token);
  //Calcular el tiempo restante en minutos
  const timeLeft = (tokenDecoded.exp - Date.now() / 1000) / 60;
  //Guardar timeLeft en localStorage
  localStorage.setItem("timeLeft", timeLeft);

  return timeLeft;
};
obtainTimeLeft();