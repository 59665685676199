import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import CheckRole from "../CheckRole";
//import ExportExcel from "react-export-excel";
import CircularProgress from "@mui/material/CircularProgress";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import makeStyles from "@mui/styles/makeStyles";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { IoMdDownload } from "react-icons/io";
import XLSX from "xlsx";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

//const ExcelFile = ExportExcel.ExcelFile;
//const ExcelSheet = ExportExcel.ExcelSheet;
//const ExcelColum = ExportExcel.ExcelColum;

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function DescargaLogs() {
  const check = new CheckRole("/descarga-logs");

  //Establece estilos de componentes
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  //Modifica temas predeterminados por Material-Table
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });

  var columnsLogsApp = [
    {
      field: "idlog_app",
      title: "Id log app",
      align: "justify",
    },
    {
      field: "fechaevento",
      title: "Fecha del Evento",
      align: "justify",
    },
    {
      field: "descripcion",
      title: "Descripcion",
      align: "justify",
    },
    {
      field: "tipoerror",
      title: "Tipo de Error",
      align: "justify",
    },
    {
      field: "argumentos",
      title: "Argumentos",
      align: "justify",
    },
    {
      field: "instancia",
      title: "Instancia",
      align: "justify",
    },
    // {
    //   field: "trace",
    //   title: "Trace",
    //   align: "justify",
    // }
  ];
  var columnsLogsVul = [
    {
      field: "idlog_app",
      title: "Id log app",
      align: "justify",
    },
    {
      field: "idvulnerabilidad",
      title: "idvulnerabilidad",
      align: "justify",
    },
    {
      field: "fechaevento",
      title: "Fecha del Evento",
      align: "justify",
    },
    {
      field: "descripcion",
      title: "Descripcion",
      align: "justify",
    },
    {
      field: "detallecampos",
      title: "Detalle campos",
      align: "justify",
    },
    {
      field: "instancia",
      title: "Instancia",
      align: "justify",
    },
    // {
    //   field: "trace",
    //   title: "Trace",
    //   align: "justify",
    // }
  ];

  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [dataLogApp, setDataLogApp] = useState([]);
  const [dataLogVul, setDataLogVul] = useState([]);
  const classes = useStyles();
  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [filter, setFilter] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);

  useEffect(() => {
    const fetchdataLogApp = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "logs/general/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setDataLogApp(data);
    };
    const fetchdataLogVul = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "logs/vulnerabilidades/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setDataLogVul(data);
    };
    fetchdataLogApp();
    fetchdataLogVul();
  }, []);

  //Habilita y desabilita los filtros por columnas
  const handleChange = () => {
    setFilter(!filter);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };
  const DescargarLogsApp = () => {
    // localStorage.setItem("iddecision", selected);
  };
  const DescargarLogsVul = () => {
    // localStorage.setItem("iddecision", selected);
  };
  const handleOnExport = (data, sheetName) => {
    const newBook = XLSX.utils.book_new(),
      sheet1 = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(newBook, sheet1, sheetName);
    XLSX.writeFile(newBook, "Descargar Log App.xlsx");
  };
  return (
    <div>
      <div className={classes.root}>
        <AlertDismissibleExample alerta={estadoPOST} />
        <Row>
          <Col>
            <h2 className='titulo'>Descarga de Logs</h2>
          </Col>
        </Row>
        <Paper variant='outlined' className={classes.paper}>
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              columns={columnsLogsApp}
              data={dataLogApp}
              icons={tableIcons}
              options={{
                rowStyle: (rowData) => ({
                  backgroundColor: "#FFF",
                }),
                showTitle: false,
                filtering: filter,
                searchFieldAlignment: "left",
                searchFieldStyle: { height: 50 }, //Llenar estilo de barra de busqueda
                headerStyle: {
                  backgroundColor: "#2c2a29",
                  color: "#ffffff",
                  position: "sticky",
                  top: 0,
                  fontFamily: "CIBFont Sans Regular",
                  fontWeight: 400,
                  fontSize: "1.2rem",
                  lineHeight: 1.6,
                  letterSpacing: "0.0075em",
                  flex: "1 1 100%",
                },
                maxBodyHeight: "650px",
                selection: false,
                disableMultipleSelection: true,
                pageSizeOptions: [10, 20, 30],
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                pageSize: 10,
              }}
              components={{
                Pagination: (props) => (
                  <div className={classes.pagination}>
                    <FormControlLabel
                      className={classes.switch}
                      control={
                        <Checkbox
                          checked={filter}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      label={
                        <Typography variant='body2'>
                          Mostrar filtros por columnas
                        </Typography>
                      }
                    />
                    <TablePagination
                      {...props}
                      className={classes.pagination}
                    />
                  </div>
                ),
              }}
              onRowClick={(evt, selectedRow) => {
                handleClick(selectedRow.iddecision);
                return setSelectedRow(selectedRow.tableData.id);
              }}
              actions={[
                {
                  icon: () => (
                    <Col md={12} className='content-info-col'>
                      <>
                        {/* {loading ? <CircularProgress /> : */}
                        <Button
                          className='botonPositivo'
                          onClick={handleOnExport(dataLogApp, "Informe")}
                        >
                          Descargar Log App &nbsp;
                          <IoMdDownload />
                        </Button>
                        {/* } */}
                      </>
                    </Col>
                  ),
                  onClick: DescargarLogsApp(),
                  position: "toolbar",
                  isFreeAction: true,
                },
              ]}
              localization={{
                toolbar: {
                  searchPlaceholder: "Buscar",
                },
                body: {
                  emptyDataSourceMessage: "No se encontraron registros",
                },
                pagination: {
                  labelRowsSelect: "Filas",
                  firstTooltip: "Primera página",
                  previousTooltip: "Anterior",
                  nextTooltip: "Siguiente",
                  lastTooltip: "Última página",
                },
              }}
            />
          </MuiThemeProvider>
        </Paper>
      </div>
      <br />
      <br />
      <div className={classes.root}>
        <Paper variant='outlined' className={classes.paper}>
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              columns={columnsLogsVul}
              data={dataLogVul}
              icons={tableIcons}
              options={{
                rowStyle: (rowData) => ({
                  backgroundColor: "#FFF",
                }),
                showTitle: false,
                filtering: filter,
                searchFieldAlignment: "left",
                searchFieldStyle: { height: 50 }, //Llenar estilo de barra de busqueda
                headerStyle: {
                  backgroundColor: "#2c2a29",
                  color: "#ffffff",
                  position: "sticky",
                  top: 0,
                  fontFamily: "CIBFont Sans Regular",
                  fontWeight: 400,
                  fontSize: "1.2rem",
                  lineHeight: 1.6,
                  letterSpacing: "0.0075em",
                  flex: "1 1 100%",
                },
                maxBodyHeight: "650px",
                selection: false,
                disableMultipleSelection: true,
                pageSizeOptions: [10, 20, 30],
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                pageSize: 10,
              }}
              components={{
                Pagination: (props) => (
                  <div className={classes.pagination}>
                    <FormControlLabel
                      className={classes.switch}
                      control={
                        <Checkbox
                          checked={filter}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      label={
                        <Typography variant='body2'>
                          Mostrar filtros por columnas
                        </Typography>
                      }
                    />
                    <TablePagination
                      {...props}
                      className={classes.pagination}
                    />
                  </div>
                ),
              }}
              onRowClick={(evt, selectedRow) => {
                handleClick(selectedRow.iddecision);
                return setSelectedRow(selectedRow.tableData.id);
              }}
              actions={[
                {
                  icon: () => (
                    <Col md={12} className='content-info-col'>
                      <>
                        {/* {loading ? <CircularProgress /> : */}
                        <Button
                          className='botonPositivo'
                          onClick={handleOnExport(dataLogVul, "Informe")}
                        >
                          Descargar Log Vulnerabilidades &nbsp;
                          <IoMdDownload />
                        </Button>
                        {/* } */}
                      </>
                    </Col>
                  ),
                  onClick: DescargarLogsVul(),
                  position: "toolbar",
                  isFreeAction: true,
                },
              ]}
              localization={{
                toolbar: {
                  searchPlaceholder: "Buscar",
                },
                body: {
                  emptyDataSourceMessage: "No se encontraron registros",
                },
                pagination: {
                  labelRowsSelect: "Filas",
                  firstTooltip: "Primera página",
                  previousTooltip: "Anterior",
                  nextTooltip: "Siguiente",
                  lastTooltip: "Última página",
                },
              }}
            />
          </MuiThemeProvider>
        </Paper>
      </div>
    </div>
  );
}

export default DescargaLogs;
