const reverseString = (str) => {
  return str.split("").reverse().join("");
};

export const DecryptData = (encryptedParam) => {
  //console.log(encryptedParam)
  const [firstParam, secondParam] = encryptedParam.split("_");

  const modifiedFirstParam = reverseString(firstParam);
  const modifiedSecondParam = reverseString(secondParam);
  const modifiedEncryptedParam = modifiedFirstParam.concat(modifiedSecondParam);
  const decryptedUser = Buffer.from(modifiedEncryptedParam, "base64").toString(
    "ascii"
  );
  //console.log(decryptedUser)
  return decryptedUser;
};

export const decryptEmail = (email) => {
  const decryptedEmail =
    email && Buffer.from(email, "base64").toString("ascii");
  const unicodeEmail = decryptedEmail.split("/").filter((str) => str !== "");
  const finalEmail = unicodeEmail
    .map((unicodeNumber) => String.fromCharCode(unicodeNumber))
    .join("");
  return finalEmail;
};

export const encryptData = (data) => {
  const base64Data = Buffer.from(data, "ascii").toString("base64");
  const half = Math.floor(base64Data.length / 2);
  const encryptedData =
    reverseString(base64Data.slice(0, half)) +
    "_" +
    reverseString(base64Data.slice(half, base64Data.length));
  return encryptedData;
};
