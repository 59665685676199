import React, { useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Accordion,
  Alert,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { uploadFile } from "react-s3";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { Link, useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";

import "bootstrap/dist/css/bootstrap.min.css";
import $, { data } from "jquery";
import "../../index.css";
import { Input } from "@mui/material";
import { Border } from "react-bootstrap-icons";
import AADService from "../../funcionesAuth.js";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import * as XLSX from "xlsx";
import { CollectionsOutlined } from "@material-ui/icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "0.5%" }} p={3}>
          <Typography component="div" style={{ padding: "0.5%" }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger">Error en la carga de las vulnerabilidades</Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Error en la categorización de las vulnerabilidades
        </Alert>
      );
      break;
    case 7:
      return <Alert variant="success">Cargo exitosamente las vulnerabilidades</Alert>;
      break;
    default:
      return null;
      break;
  }
}
const colors = {
  descripcion: "#fdda24",
  resultado: "#00c398",
  calificacionCV: "#ff7f41",
  valoracionEB: "#F5B6CD",
  calificacionEB: "#59CBE8",
};
const rows0 = [
  //Revisar siempre cuantas columnas hacen parte
  {
    field: "descripcion_de_la_vulnerabilidad",
    title: "DESCRIPCIÓN DE LA VULNERABILIDAD",
    col: 4,
    group: "Descripcion",
    color: colors.descripcion,
  },
  {
    field: "resultado_del_escaneo",
    title: "RESULTADO DEL ESCANEO",
    col: 19,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "calificacion_de_la_vulnerabilidad_cvss",
    title: "CALIFICACIÓN DE LA VULNERABILIDAD - CVSS",
    col: 9,
    group: "CalificacionCV",
    color: colors.calificacionCV,
  },
  {
    field: "valoracion_de_la_vulnerabilidad_banco",
    title: "VALORACIÓN DE LA VULNERABILIDAD - ENTORNO BANCO",
    col: 9,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  {
    field: "calificacion_de_la_vulnerabilidad_banco",
    title: "CALIFICACIÓN DE LA VULNERABILIDAD - ENTORNO BANCO",
    col: 8,
    group: "CalificacionEB",
    color: colors.calificacionEB,
  },
];
const rows1 = [
  {
    field: "ve_vectorataque",
    title: "Vector de ataque (AV)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "ve_complejidadataque",
    title: "Complejidad ataque (AC)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "ve_privilegiosrequeridos",
    title: "Privilegios requeridos (PR)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "ve_interaccionusuario",
    title: "Interacción de usuario (UI)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vi_confidencialidad",
    title: "Confidencialidad (C)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vi_integridad",
    title: "Integridad (I)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vi_disponibilidad",
    title: "Disponibilidad (A)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vi_alcance",
    title: "Alcance (S)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vt_explotabilidad",
    title: "Explotabilidad (E)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vt_nivelremediacion",
    title: "Nivel de remediación (RL)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vt_reporteconfianza",
    title: "Reporte de confianza (RC)",
    row: 1,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vvebex_ubicacionlogica",
    title: "Ubicación lógica",
    row: 1,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  {
    field: "vvebex_persistencia",
    title: "Persistencia",
    row: 1,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  {
    field: "vvebex_alcance",
    title: "Alcance",
    row: 1,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  {
    field: "vvenex_scope",
    title: "Scope(s)",
    row: 1,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  {
    field: "vvebimp_confidencialidad",
    title: "Confidencialidad activo(CR)",
    row: 1,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  {
    field: "vvebimp_integridad",
    title: "Integridad activo(IR)",
    row: 1,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  {
    field: "vvebimp_disponibilidad",
    title: "Disponibilidad activo(AR)",
    row: 1,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  {
    field: "vvebimp_relevancia",
    title: "Relevancia componente",
    row: 1,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  {
    field: "vvebimp_tipoambiente",
    title: "Tipo ambiente",
    row: 1,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
];
const columns = [
  //Inicio Descripcion Vulnerabilidades
  {
    field: "idvulnerabilidad_tecnica",
    title: "Id Vulnerabilidad",
    row: 2,
    group: "Descripcion",
    color: colors.descripcion,
  },
  {
    field: "fuente",
    title: "Fuente de identificación",
    row: 2,
    group: "Descripcion",
    color: colors.descripcion,
  },
  {
    field: "categoria_vulnerabilidad",
    title: "Categoría",
    row: 2,
    group: "Descripcion",
    color: colors.descripcion,
  },
  {
    field: "vulnerabilidad",
    title: "Tipo",
    row: 2,
    group: "Descripcion",
    color: colors.descripcion,
  },
  //Fin Descripcion Vulnerabilidades
  //Inicio Resultado del Escaneo
  {
    field: "nombrecomponente",
    title: "Nombre del componente",
    row: 2,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "ip",
    title: "Dirección IP",
    row: 2,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "detallevulnerabilidad",
    title: "Detalle de la Vulnerabilidad",
    row: 2,
    col: 4,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "fechapublicacionvuln",
    title: "Fecha Publicación Vulnerabilidad",
    row: 2,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "ultimafechaobservacion",
    title: "Última Fecha Observación",
    row: 2,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vector_de_explotabilidad",
    title: "Vector de explotabilidad",
    col: 4,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vector_de_impacto",
    title: "Vector de impacto",
    col: 4,
    group: "Resultado",
    color: colors.resultado,
  },
  {
    field: "vector_de_temporalidad",
    title: "Vector de temporalidad",
    col: 3,
    group: "Resultado",
    color: colors.resultado,
  },
  //Fin Resultado del Escaneo
  //Inicio calificacionCV
  {
    field: "vectorexplotabilidadbasei",
    title: "Vector de explotabilidad base*",
    row: 2,
    group: "CalificacionCV",
    color: colors.calificacionCV,
  },
  {
    field: "clasificacionseguridad",
    title: "Clasificación seguridad*",
    row: 2,
    group: "CalificacionCV",
    color: colors.calificacionCV,
  },
  {
    field: "vectorimpactobase",
    title: "Vector de impacto base*",
    row: 2,
    group: "CalificacionCV",
    color: colors.calificacionCV,
  },
  {
    field: "scorebase",
    title: "Score Base*",
    row: 2,
    group: "CalificacionCV",
    color: colors.calificacionCV,
  },
  {
    field: "cvss_vectexplottemporalidad",
    title: "Vector de explotabilidad con temporalidad",
    row: 2,
    group: "CalificacionCV",
    color: colors.calificacionCV,
  },
  {
    field: "cvss_vectorimpactobase",
    title: "Vector de impacto base",
    row: 2,
    group: "CalificacionCV",
    color: colors.calificacionCV,
  },
  {
    field: "cvss_score",
    title: "Score",
    row: 2,
    group: "CalificacionCV",
    color: colors.calificacionCV,
  },
  {
    field: "vectorexplotabilidadbaseii",
    title: "Vector explotabilidad base'",
    row: 2,
    group: "CalificacionCV",
    color: colors.calificacionCV,
  },
  {
    field: "vectorexplotabilidad",
    title: "Vector de explotabilidad'",
    row: 2,
    group: "CalificacionCV",
    color: colors.calificacionCV,
  },
  //Fin calificacionCV
  //Inicio valoracion
  {
    field: "explotabilidad",
    title: "Explotabilidad",
    col: 4,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  {
    field: "impacto",
    title: "Impacto",
    col: 5,
    group: "ValoracionEB",
    color: colors.valoracionEB,
  },
  //Fin valoracion
  //Inicio calificacionBan
  {
    field: "cveb_vectorexplototrasfuentes",
    title: "Vector de explotabilidad otras fuentes",
    row: 2,
    group: "CalificacionEB",
    color: colors.calificacionEB,
  },
  {
    field: "cveb_vectorexplotbco",
    title: "Vector de explotabilidad Banco",
    row: 2,
    group: "CalificacionEB",
    color: colors.calificacionEB,
  },
  {
    field: "cveb_isc",
    title: "ISC*",
    row: 2,
    group: "CalificacionEB",
    color: colors.calificacionEB,
  },
  {
    field: "cveb_vectorimpactobco",
    title: "Vector de impacto Banco",
    row: 2,
    group: "CalificacionEB",
    color: colors.calificacionEB,
  },
  {
    field: "cveb_vectorscorebco",
    title: "Score Banco",
    row: 2,
    group: "CalificacionEB",
    color: colors.calificacionEB,
  },
  {
    field: "cveb_factorexposicion",
    title: "Factor de exposición",
    row: 2,
    group: "CalificacionEB",
    color: colors.calificacionEB,
  },
  {
    field: "cveb_lateralidad",
    title: "¿Existe efecto de lateralidad?",
    row: 2,
    group: "CalificacionEB",
    color: colors.calificacionEB,
  },
  //Fin calificacionBan
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

var responsePost = null;

const cellEditProp = {
  mode: "click",
  blurToSave: true,
};
const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "70vh",
    minHeight: "70vh",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

let reclaVul = null;
function Vul_tec() {
  //constantes para el funcionamiento de la Tabla
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [queryText, setqueryText] = React.useState("");
  const classes = useStyles();
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [msgerror, setMsgerror] = React.useState("");
  const [error, setError] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [data, setData] = React.useState([]);
  const myRef = React.createRef();
  const [postVul, setPostVul] = React.useState([]);
  const [loadingNew, setLoadingNew] = React.useState(false);
  const [loadingCarge, setLoadingCarge] = React.useState(false);
  const [loadingRe, setLoadingRe] = React.useState(false);
  //Columnas individuales
  const [modalShow, setModalShow] = React.useState(false);
  const [value, setValue] = React.useState(0);

  //Definición de estados para ocultar columnas
  const [descripcionState, setDescripcionState] = React.useState(true);
  const [resultadoState, setResultadoState] = React.useState(true);
  const [calificacionCVState, setCalificacionCVState] = React.useState(true);
  const [valoracionState, setValoracionState] = React.useState(true);
  const [calificacionBanState, setCalificacionBanState] = React.useState(true);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const [validRole, setValidRole] = React.useState(
    localStorage.getItem("rolusuario") == 2 ||
      localStorage.getItem("rolusuario") == 1
      ? true
      : false
  );

  const handleChange = (event, newValue) => {
    if (newValue == 0) {
      const fetchdataValue = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
          "evaluacion/vultec/" +
          localStorage.getItem("idevaluacion") +
          "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let param = await result.json();
        if (result.status >= 200 && result.status < 300) {
          setData(param);
        } else {
          setData(null);
        }
      };
      fetchdataValue();
    }
    setValue(newValue);
  };

  function MyVerticallyCenteredModal(props) {
    const [archivo, setArchivo] = React.useState([]);
    const [stateArchivo, setStateArchivo] = React.useState([]);
    const [names, setNames] = React.useState([]);
    const [namesN, setNamesN] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const timeElapsed = Date.now();
    //const client = new W3CWebSocket('ws://'+ process.env.REACT_APP_API_URL + '/ws/' + 'vultec' + '/');
    const AzureADService = new AADService();
    const token = AzureADService.getToken();
    let history = useHistory();
    const today = new Date(timeElapsed);
    let tempArchivos = [];
    let temp = [];
    let temp2 = [];
    const fuente = [
      { id: "1", nombre: "Fluid" },
      { id: "2", nombre: "Tenable" },
      { id: "3", nombre: "Ethical Hacking" },
      { id: "4", nombre: "Prisma Cloud" },
    ];

    const subirArchivo = (e) => {
      setEstadoPost(0);
      setArchivo([]);
      setNames([]);
      setStateArchivo([]);
      setNames([]);
      setNamesN([]);
      let selects = document.getElementsByClassName("selects");
      for (let index = 0; index < selects.length; index++) {
        const element = selects[index];
        element.value = "";
      }

      for (let i = 0; i < e.length; i++) {
        if (
          e[i].name.split(".")[1] == "xlsx" ||
          e[i].name.split(".")[1] == "csv"
        ) {
          temp.push(e[i].name);
          tempArchivos.push(e[i]);
        }
      }
      setArchivo(tempArchivos);
      setNames(temp);
    };
    const renameArchivo = (file) => {
      const formData = new FormData();
      for (let i = 0; i < archivo.length; i++) {
        formData.append("archivo", archivo[i], file[i]);
        formData.append("id", i);
      }
      setArchivo(formData.getAll("archivo"));
    };
    const nuevoN = (i) => {
      let fuente = document.getElementById("fuente" + i).value;

      if (fuente == "Ethical Hacking") {
        fuente = "EH"
      }

      if (fuente == "Prisma Cloud") {
        fuente = "Prisma"
      }


      if (namesN.length !== 0) {
        namesN.map((dat) => temp2.push(dat));
        if (i <= temp2.length) {
          temp2[i] =
            fuente + "_" + today.toISOString() + "." + names[i].split(".")[1];
        } else {
          temp2.push(
            fuente + "_" + today.toISOString() + "." + names[i].split(".")[1]
          );
        }
      } else {
        temp2.push(
          fuente + "_" + today.toISOString() + "." + names[i].split(".")[1]
        );
      }
      setNamesN(temp2);
      renameArchivo(temp2);
    };
    const enviarLotes = (data) => {
      let datos = JSON.stringify(data);
      fetch(process.env.REACT_APP_API_URL + "vulnerabilidad/Tecnica/", {
        method: "POST",
        body: datos,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status >= 200 && response.status < 300) {
            //props.onHide();   
            setLoadingCarge(false);
            setLoadingNew(false);
            responsePost = data;
            const button1 = document.getElementById("simple-tab-1");
            button1.click();
            setEstadoPost(7);
            return responsePost;
          } else if (response.status >= 500) {
            setLoadingCarge(false);
            setLoadingNew(false);
            setMsgerror(data.msg_error);
            setError(true)
            return 500;
            //props.onHide();
          } else if (response.status >= 400 && response.status < 500) {
            setLoading(false);
            setProgress(false);
            setLoadingCarge(false);
            return 400;
            //props.onHide();
          }
        })
      );
    };
    const postArchivo = async (event, file) => {
      event.preventDefault();
      setLoadingCarge(true);
      setLoadingNew(true);
      setError(false);
      const form = event.currentTarget;
      let temp = [];
      let archivosKEY = [];
      let hojas = [];
      //let client = new W3CWebSocket('ws://127.0.0.1:8000/ws/' + 'vultec' + '/');
      let reader = new FileReader();
      //let contentBuffer = await readFileAsync(this.state.archivo);
      reader.readAsArrayBuffer(file[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array", cellDates: true });
        workbook.SheetNames.forEach(function (sheetName) {
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          hojas.push({
            data: XL_row_object,
            sheetName,
          });
        });
        let lotes = [];
        let result = hojas[0].data.length;
        let rango = hojas[0].data.length / 10;
        let inicial = 0;
        let dataHoja = hojas[0].data;
        if (result < 100) {
          lotes.push([0, result - 1]);
        } else {
          for (let i = 1; i <= 10; i++) {
            if (i == 10) {
              lotes.push([inicial, result - 1]);
            } else {
              lotes.push([inicial, rango * i]);
            }
            inicial = rango * i + 1;
          }
        }
        var data = [];
        for (let i = 0; i < lotes.length; i++) {
          var temp = dataHoja.slice(lotes[i][0], lotes[i][1] + 1);
          data.push({
            idusuario: localStorage.getItem("idusuario"),
            idcompania: localStorage.getItem("idcompania"),
            usuario: localStorage.getItem("usuario"),
            idevaluacion: localStorage.getItem("idevaluacion"),
            idactivo: localStorage.getItem("idactivo"),
            key: "arch/" + namesN[0],
            message: temp,
          });
        }
        let dataTotal = [];
        let status = data.map((dato) => {
          enviarLotes(dato);
        });
        if (status.includes(400) || status.includes(500)) {
        } else {
          // setEstadoPost(2);
          props.onHide();
          const fetchdataNewPost = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
              "evaluacion/vultec/" +
              localStorage.getItem("idevaluacion") +
              "/",
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let paramNew = await result.json();
            setLoading(false);
            setProgress(false);
            if (paramNew.length >= 1) {
              setData(paramNew);
            } else {
              setData([]);
            }
          };
          fetchdataNewPost();
        }
      };
    };
    const construirJSON = async (archivosKEY, temp) => {
      setStateArchivo(temp);
      setEstadoPost(0);
      script_alistamiento(archivosKEY);
    };

    const script_alistamiento = async (archivosKEY) => {
      setLoading(true);
      setProgress(true);
      var data = JSON.stringify({
        idusuario: localStorage.getItem("idusuario"),
        idcompania: localStorage.getItem("idcompania"),
        usuario: localStorage.getItem("usuario"),
        key: archivosKEY,
      });
      fetch(process.env.REACT_APP_API_URL + "vulnerabilidad/Tecnica/", {
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status >= 200 && response.status < 300) {
            responsePost = data;
            // setEstadoPost(2);
            setLoading(false);
            setProgress(false);
            setLoadingCarge(false);
            setLoadingNew(false);
            //props.onHide();
            const button1 = document.getElementById("simple-tab-1");
            button1.click();
            const fetchdataNewPost = async () => {
              const result = await fetch(
                process.env.REACT_APP_API_URL +
                "evaluacion/vultec/" +
                localStorage.getItem("idevaluacion") +
                "/",
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                  },
                }
              );
              let paramNew = await result.json();
              setData(paramNew);
            };
            fetchdataNewPost();
          } else if (response.status >= 500) {
            setEstadoPost(6);
            setLoadingCarge(false);
            setLoadingNew(false);
            setLoading(false);
            setProgress(false);
            return 500;
            //props.onHide();
          } else if (response.status >= 400 && response.status < 500) {
            setEstadoPost(5);
            setLoading(false);
            setProgress(false);
            setLoadingCarge(false);
            return 400;
            //props.onHide();
          }
        })
      );
      setLoading(false);
    };

    if (loading) {
      return (
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          data-keyboard="false"
        >
          <Form onSubmit={(e) => postArchivo(e, archivo)}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Cargar archivos
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AlertDismissibleExample alerta={estadoPOST} />
              {/* <Loader type="Circles" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/>
          <Loader type="TailSpin" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/> */}
              <Loader
                type="Oval"
                color="#FFBF00"
                style={{ textAlign: "center", position: "static" }}
              />
              {/* 
          <Loader type="Puff" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/>
          <Loader type="Audio" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/> */}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Form>
        </Modal>
      );
    } else if (!loading) {
      return (
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Form onSubmit={(e) => postArchivo(e, archivo)}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Cargar archivos
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AlertDismissibleExample alerta={estadoPOST} />
              <Row className="mb-3">
                <Col>
                  <input
                    type="file"
                    name="files"
                    accept=".xlsx,.csv"
                    multiple
                    onChange={(e) => subirArchivo(e.target.files)}
                  ></input>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <Row>
                        <Col sm={4}>
                          <h5>Nombre del archivo</h5>
                        </Col>
                        <Col sm={3}>
                          <h5>Fuente</h5>
                        </Col>
                        <Col sm={4}>
                          <h5>Nombre en el servidor</h5>
                        </Col>
                        <Col sm={1}>
                          <h5>Estado</h5>
                        </Col>
                      </Row>
                    </li>
                    {names.map((item, index) => (
                      <li className="list-group-item" key={index}>
                        <Row>
                          <Col sm={4}>{item}</Col>
                          <Col sm={3}>
                            <select
                              className="form-control selects"
                              required
                              id={"fuente" + index}
                              onChange={() => nuevoN(index)}
                            >
                              <option value="" selected="true" disabled="disabled">Seleccione la fuente</option>
                              {fuente.map((data) => (
                                <option key={data.id} value={data.nombre}>
                                  {data.nombre}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col sm={4}>{namesN[index]}</Col>
                          <Col sm={1}>{stateArchivo[index]}</Col>
                        </Row>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {progress && <CircularProgress />}
              <Button type="submit" className="botonPositivo">
                Cargar
              </Button>
              <Button onClick={props.onHide} className="botonNegativo">
                Cancelar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      );
    }
  }
  useEffect(() => {
    const fetchdata = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "evaluacion/vultec/" +
        localStorage.getItem("idevaluacion") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let param = await result.json();
      if (result.status >= 200 && result.status < 300) {
        setData(param);
      } else {
        setData(null);
      }
    };
    fetchdata();
  }, []);
  const nada = (e) => {
    e.preventDefault();
  };
  function enviarRecla(dato) {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_API_URL +
        "evaluacion/vultec/" +
        localStorage.getItem("idevaluacion") +
        "/",
        {
          method: "PUT",
          body: JSON.stringify(dato),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      ).then((response) => {
        response.json().then((data) => {
          if (response.status >= 200 && response.status < 300) {
            reclaVul = data;
            setLoadingRe(false);
            setLoadingNew(false);
            const button2 = document.getElementById("simple-tab-2");
            button2.click();
            setEstadoPost(2);
            resolve(data);
          } else if (response.status >= 500) {
            setLoadingRe(false);
            setEstadoPost(6);
            setLoadingCarge(false);
            setLoadingNew(false);
            reject(data);
          } else if (response.status >= 400 && response.status < 500) {
            setLoadingRe(false);
            setLoadingCarge(false);
            setLoadingNew(false);
            setEstadoPost(4);
            reject(data);
          }
        });
      }).catch((error) => {
        reject(error); // Rechazar si hay errores en la solicitud fetch
      });
    });
  }
  
  const procesarDatosSecuencialmente = async (datos) => {
    const tamañoLote = 200;
    let totalRegistros = datos.length;
  
    for (let i = 0; i < totalRegistros; i += tamañoLote) {
      const lote = datos.slice(i, i + tamañoLote);
  
      try {
        await enviarRecla({ idusuariocreacion: parseInt(localStorage.getItem("idusuario")), vultecnicas: lote });
        console.log("Lote # " + (i / tamañoLote + 1) + " enviado")
        await esperar(3000); // Espera 3 segundos (3000 milisegundos) entre lotes
  
      } catch (error) {
        console.error("Error al enviar datos:", error);
        setLoadingCarge(false);
        setLoadingRe(false);
        setError(true);
        return;
      }
    }
  
    // Todos los lotes completos se han enviado, ahora envía el último lote con los registros restantes
    const registrosRestantes = totalRegistros % tamañoLote;
    if (registrosRestantes > 0) {
      const ultimoLote = datos.slice(totalRegistros - registrosRestantes);
      try {
        await enviarRecla({ idusuariocreacion: parseInt(localStorage.getItem("idusuario")), vultecnicas: ultimoLote });
        console.log("Lote restante enviado")
      } catch (error) {
        console.error("Error al enviar datos restantes:", error);
        setLoadingCarge(false);
        setLoadingRe(false);
        setError(true);
        return;
      }
    }
  
    // Todos los datos se han enviado
    setLoadingCarge(false);
    setLoadingRe(false);
  
    // Fetch para obtener los nuevos datos después del envío
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/vultec/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    let paramNew = await result.json();
    setData(paramNew);
  };
// Función para esperar un cierto número de milisegundos
const esperar = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const enviarVul = async () => {
    console.log("Inicia envio de datos")
    setLoadingRe(true);
    setLoadingNew(true);
    setError(false);
    let datos = data;
    let datos_nuevos = [];
    let lotes = [];
    let result = datos.length;
    let rango = datos.length / 20;
    let inicial = 0;
    //Recorrer los datos y almacenar en resul_news los que tengan vació o null la columna 'cveb_vectorscorebco'
    for (let i = 0; i < datos.length; i++) {
      if (datos[i].cveb_vectorscorebco == null || datos[i].cveb_vectorscorebco == "") {
        datos_nuevos.push(datos[i]);
      }
    }
    //Si datos_nuevos tiene datos
    if (datos_nuevos.length > 0) {
      console.log("Hay datos con cveb_vectorscorebco vacío")
      datos = datos_nuevos;
      result = datos.length;
      rango = datos.length / 20;
      }
      
    if (result < 100) {
      lotes.push([0, result - 1]);
    } else {
      for (let i = 1; i <= 20; i++) {
        if (i == 20) {
          lotes.push([inicial, result - 1]);
        } else {
          lotes.push([inicial, rango * i]);
        }
        inicial = rango * i + 1;
      }
    }
    var dataEnviar = [];
    for (let i = 0; i < lotes.length; i++) {
      var temp = datos.slice(lotes[i][0], lotes[i][1] + 1);
      dataEnviar.push({
        idusuariocreacion: parseInt(localStorage.getItem("idusuario")),
        vultecnicas: temp,
      });
    }
    var envioData = dataEnviar.map((dato) => {
      enviarRecla(dato);
    });
    if (envioData.includes(400) || envioData.includes(500)) {
    } else {
      setLoadingCarge(false);
      setLoadingRe(false);
      // setLoadingNew(false);
      reclaVul = data;
      const fetchdataNew = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
          "evaluacion/vultec/" +
          localStorage.getItem("idevaluacion") +
          "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let paramNew = await result.json();
        setData(paramNew);
      };
      fetchdataNew();
    }
  };

  const onHeaderClick = (val) => (e) => {
    if (val == "descripcion") {
      if (descripcionState == true) {
        setDescripcionState(false);
      } else {
        setDescripcionState(true);
      }
    } else if (val == "resultado") {
      if (resultadoState == true) {
        setResultadoState(false);
      } else {
        setResultadoState(true);
      }
    } else if (val == "calificacionCVSS") {
      if (calificacionCVState == true) {
        setCalificacionCVState(false);
      } else {
        setCalificacionCVState(true);
      }
    } else if (val == "valoracion") {
      if (valoracionState == true) {
        setValoracionState(false);
      } else {
        setValoracionState(true);
      }
    } else if (val == "calificacionBan") {
      if (calificacionBanState == true) {
        setCalificacionBanState(false);
      } else {
        setCalificacionBanState(true);
      }
    }
  };
  /*
  const onMostrarClick = () => {
    setDescripcionState(false);
    setResultadoState(false);
    setCalificacionCVState(false);
    setValoracionState(false);
    setCalificacionBanState(false);
  };
  */

  function numberFormatter(cell, row) {
    return parseInt(cell).toFixed(2);
  }
  const evaluationState = JSON.parse(localStorage.getItem("estado_evaluacion"));
  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />
      {error == true ? <Alert variant="danger">{msgerror}</Alert> : ""}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <AppBar
        position="static"
        style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="Vulnerabilidades"
            {...a11yProps(0)}
            className="vult-title"
            style={{ padding: "3px 7px 3px" }}
          />
          <Tab
            label="Informe de escaneo"
            {...a11yProps(1)}
            className="vult-title"
            style={{ padding: "3px 7px 3px" }}
          />
          <Tab
            label="Informe reclasificación"
            {...a11yProps(2)}
            className="vult-title"
            style={{ padding: "3px 7px 3px" }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {!loadingNew ? (
          <>
            <Form onSubmit={(e) => nada(e)}>
              <Row className="mb-1">
                <Col sm={6}>
                  <Row>
                    {data !== null ? (
                      <>
                        {/*
                        <Col sm={3}>
                          <Button
                            className="btn-warning"
                            onClick={() => onMostrarClick()}
                            style={{ borderRadius: "30px" }}
                          >
                            Ver Todo
                          </Button>
                        </Col>
                      */}
                        <Col sm={1}>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip-bottom">
                                Mostrar/ocultar descripción.
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={onHeaderClick("descripcion")}
                              style={{
                                backgroundColor: colors.descripcion,
                                border: "none",
                                color: "black",
                              }}
                            >
                              1
                            </Button>
                          </OverlayTrigger>
                        </Col>
                        <Col sm={1}>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip-bottom">
                                Mostrar/ocultar Resultado del escaneo.
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={onHeaderClick("resultado")}
                              style={{
                                backgroundColor: colors.resultado,
                                border: "none",
                              }}
                            >
                              2
                            </Button>
                          </OverlayTrigger>
                        </Col>
                        <Col sm={1}>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip-bottom">
                                Mostrar/ocultar Calificación de la
                                vulnerabilidad CVSS.
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={onHeaderClick("calificacionCVSS")}
                              style={{
                                backgroundColor: colors.calificacionCV,
                                border: "none",
                                color: "black",
                              }}
                            >
                              3
                            </Button>
                          </OverlayTrigger>
                        </Col>
                        <Col sm={1}>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip-bottom">
                                Mostrar/ocultar Valoración de la vulnerabilidad
                                Entorno Banco.
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={onHeaderClick("valoracion")}
                              style={{
                                backgroundColor: colors.valoracionEB,
                                border: "none",
                              }}
                            >
                              4
                            </Button>
                          </OverlayTrigger>
                        </Col>
                        <Col sm={1}>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip-bottom">
                                Mostrar/ocultar Calificación de la
                                vulnerabilidad Entorno Banco.
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={onHeaderClick("calificacionBan")}
                              style={{
                                backgroundColor: colors.calificacionEB,
                                border: "none",
                                color: "black",
                              }}
                            >
                              5
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                </Col>
                <Col sm={3}>
                  {validRole &&evaluationState? (
                    <Button
                      className="botonIngreso"
                      onClick={() => setModalShow(true)}
                    >
                      Cargue de vulnerabilidades
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
                <Col sm={3}>
                  {validRole&&evaluationState ? (
                    <Button
                      onClick={() => enviarVul()}
                      className="botonPositivo"
                    >
                      Reclasificar vulnerabilidades
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              {data !== null ? (
                <Paper variant="outlined" className={classes.paper}>
                  <TableContainer className={classes.container}>
                    <Table
                      stickyHeader
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size="medium"
                      aria-label="enhanced table"
                    >
                      <TableHead>
                        <TableRow>
                          {rows0.map((headCell) => (
                            <TableCell
                              key={headCell.field}
                              align={"center"}
                              style={{
                                backgroundColor: headCell.color,
                                color: "#ffffff",
                                border: "1px solid #000000",
                              }}
                              rowSpan={headCell.row}
                              colSpan={
                                headCell.col != null &&
                                  headCell.col != undefined
                                  ? headCell.col
                                  : 1
                              }
                              hidden={
                                !(
                                  (headCell.group == "Descripcion" &&
                                    descripcionState) ||
                                  (headCell.group == "Resultado" &&
                                    resultadoState) ||
                                  (headCell.group == "CalificacionCV" &&
                                    calificacionCVState) ||
                                  (headCell.group == "ValoracionEB" &&
                                    valoracionState) ||
                                  (headCell.group == "CalificacionEB" &&
                                    calificacionBanState)
                                )
                              }
                            >
                              <label className="label">{headCell.title}</label>
                            </TableCell>
                          ))}
                        </TableRow>
                        <>
                          <TableRow>
                            {columns.map((headCell) => (
                              <TableCell
                                key={headCell.field}
                                align={"center"}
                                style={{
                                  backgroundColor: headCell.color,
                                  color: "#ffffff",
                                  border: "1px solid #000000",
                                }}
                                rowSpan={headCell.row}
                                colSpan={
                                  headCell.col != null &&
                                    headCell.col != undefined
                                    ? headCell.col
                                    : 1
                                }
                                hidden={
                                  !(
                                    (headCell.group == "Descripcion" &&
                                      descripcionState) ||
                                    (headCell.group == "Resultado" &&
                                      resultadoState) ||
                                    (headCell.group == "CalificacionCV" &&
                                      calificacionCVState) ||
                                    (headCell.group == "ValoracionEB" &&
                                      valoracionState) ||
                                    (headCell.group == "CalificacionEB" &&
                                      calificacionBanState)
                                  )
                                }
                              >
                                <label className="texto">
                                  {headCell.title}
                                </label>
                              </TableCell>
                            ))}
                          </TableRow>
                          <TableRow>
                            {rows1.map((headCell) => (
                              <TableCell
                                key={headCell.field}
                                align={"center"}
                                style={{
                                  backgroundColor: headCell.color,
                                  color: "#ffffff",
                                  border: "1px solid #000000",
                                }}
                                colSpan={headCell.col}
                                hidden={
                                  !(
                                    (headCell.group == "Descripcion" &&
                                      descripcionState) ||
                                    (headCell.group == "Resultado" &&
                                      resultadoState) ||
                                    (headCell.group == "CalificacionCV" &&
                                      calificacionCVState) ||
                                    (headCell.group == "ValoracionEB" &&
                                      valoracionState) ||
                                    (headCell.group == "CalificacionEB" &&
                                      calificacionBanState)
                                  )
                                }
                              >
                                <label className="texto">
                                  {headCell.title}
                                </label>
                              </TableCell>
                            ))}
                          </TableRow>
                        </>
                      </TableHead>
                      <TableBody>
                        {stableSort(data, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((vulT, index) => {
                            return (
                              <TableRow key={vulT.idvulnerabilidad_tecnica}>
                                {descripcionState ? (
                                  <>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.idvulnerabilidad_tecnica}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.fuente}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.categoria_vulnerabilidad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vulnerabilidad}
                                    </TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                                {resultadoState ? (
                                  <>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.nombrecomponente}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.ip}
                                    </TableCell>
                                    <TableCell
                                      colSpan={4}
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.detallevulnerabilidad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.fechapublicacionvuln}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.ultimafechaobservacion}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.ve_vectorataque}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.ve_complejidadataque}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.ve_privilegiosrequeridos}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.ve_interaccionusuario}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vi_confidencialidad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vi_integridad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vi_disponibilidad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vi_alcance}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vt_explotabilidad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vt_nivelremediacion}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vt_reporteconfianza}
                                    </TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                                {calificacionCVState ? (
                                  <>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vectorexplotabilidadbasei}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.clasificacionseguridad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vectorimpactobase}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.scorebase}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.cvss_vectexplottemporalidad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.cvss_vectorimpactobase}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.cvss_score}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vectorexplotabilidadbaseii}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vectorexplotabilidad}
                                    </TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                                {valoracionState ? (
                                  <>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vvebex_ubicacionlogica}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vvebex_persistencia}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vvebex_alcance}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vvenex_scope}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vvebimp_confidencialidad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vvebimp_integridad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vvebimp_disponibilidad}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vvebimp_relevancia}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.vvebimp_tipoambiente}
                                    </TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                                {calificacionBanState ? (
                                  <>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.cveb_vectorexplototrasfuentes}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.cveb_vectorexplotbco}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.cveb_isc}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.cveb_vectorimpactobco}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.cveb_vectorscorebco}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.cveb_factorexposicion}
                                    </TableCell>
                                    <TableCell
                                      style={{ border: "0.5px solid #D1CDCC" }}
                                    >
                                      {vulT.cveb_lateralidad ? "SI" : "NO"}
                                    </TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Row>
                    <Col sm={4} xs={4}></Col>
                    <Col xs={8}>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"Filas por página"}
                      />
                    </Col>
                  </Row>
                </Paper>
              ) : (
                <Row className="mb-3">
                  <Row className="mb-3">
                    <Col></Col>
                  </Row>
                  <Col className="text-center">
                    <Typography variant="h6" id="tableTitle" component="div">
                      <br></br>
                      No hay vulnerabilidades en el momento
                    </Typography>
                  </Col>
                </Row>
              )}
            </Form>
          </>
        ) : (
          <>
            <Loader
              type="Puff"
              color="#FFBF00"
              style={{ textAlign: "center", position: "static" }}
            />
            <Row className="mb-3">
              <Col className="text-center" sm={12} xs={12}>
                {loadingCarge ?
                  <label className="form-label">
                    Cargando vulnerabilidades, por favor espere.
                  </label> :
                  <label className="form-label">
                    Reclasificando vulnerabilidades, por favor espere.
                  </label>
                }
              </Col>
            </Row>
          </>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {responsePost !== null ? (
          <>
            <Row className="mb-3">
              <Col md={6}>
                <Row className="mb-3">
                  <Col>
                    <Typography variant="h6" id="tableTitle" component="div">
                      Informe de alistamiento
                    </Typography>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">Fuente</label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_alistamiento"][0]["Fuente"]
                      }
                    ></input>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">
                      Total de vulnerabilidades archivadas
                    </label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_alistamiento"][0]["Total_vuln_arch"]
                      }
                    ></input>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">
                      Tienen nulo el vector CVSS
                    </label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_alistamiento"][0]["nullxcvss"]
                      }
                    ></input>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">Registros duplicados</label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_alistamiento"][0][
                        "registro_duplicados"
                        ]
                      }
                    ></input>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">
                      Contienen fecha de publicación nula
                    </label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_alistamiento"][0][
                        "Formato_incorr_vuln_pub"
                        ]
                      }
                    ></input>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">Procesadas por CVSS v2</label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_alistamiento"][0]["vulnprocxcvss2"]
                      }
                    ></input>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">Procesadas por CVSS v3</label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_alistamiento"][0]["vulnprocxcvss3"]
                      }
                    ></input>
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <Row className="mb-3">
                  <Col>
                    <Typography variant="h6" id="tableTitle" component="div">
                      Informe de categorización
                    </Typography>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">
                      Vulnerabilidades a categorizar
                    </label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_categorizacion"]["Tot_vuln_a_cat"]
                      }
                    ></input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">
                      Vulnerabilidades categorizadas
                    </label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_categorizacion"]["Tot_vuln_cat"]
                      }
                    ></input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">
                      Vulnerabilidades no categorizadas
                    </label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_categorizacion"]["Vuln_no_cat"]
                      }
                    ></input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">
                      Vulnerabilidades actualizadas
                    </label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_categorizacion"]["Vuln_actu"]
                      }
                    ></input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={9} xs={12}>
                    <label className="form-label">
                      Vulnerabilidades nuevas
                    </label>
                  </Col>
                  <Col sm={3} xs={12}>
                    <input
                      type="text"
                      className="form-control text-center"
                      defaultValue={
                        responsePost["Inf_categorizacion"]["Vuln_nue"]
                      }
                    ></input>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mb-3">
            <Col className="text-center">
              <p>
                Debe de primero cargar vulnerabilidades para ver su informe de
                escaneo
              </p>
            </Col>
          </Row>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {reclaVul !== null ? (
          <>
            <Row className="mb-3">
              <Col>
                <Typography variant="h6" id="tableTitle" component="div">
                  Informe de reclasificación
                </Typography>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col className="text-center" sm={12} xs={12}>
                <label className="form-label">{reclaVul["Resultado"]}</label>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={9} xs={12}>
                <label className="form-label">
                  Total de registros procesados
                </label>
              </Col>
              <Col sm={3} xs={12}>
                <input
                  type="text"
                  className="form-control text-center"
                  defaultValue={reclaVul["Total_Registros_Procesados"]}
                ></input>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={9} xs={12}>
                <label className="form-label">
                  Total de registros con errores al guardar
                </label>
              </Col>
              <Col sm={3} xs={12}>
                <input
                  type="text"
                  className="form-control text-center"
                  defaultValue={
                    reclaVul["Total_Registros_con_Errores_al_Guardar"]
                  }
                ></input>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={9} xs={12}>
                <label className="form-label">
                  Registros con errores al reclasificar
                </label>
              </Col>
              <Col sm={3} xs={12}>
                <input
                  type="text"
                  className="form-control text-center"
                  defaultValue={
                    reclaVul["Total_Registros_con_Errores_al_Reclasificar"]
                  }
                ></input>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mb-3">
            <Col className="text-center">
              <p>
                Debe de primero reclasificar las vulnerabilidades para ver el
                informe
              </p>
            </Col>
          </Row>
        )}
      </TabPanel>
    </>
  );
}
export default Vul_tec;
