import React, { Component, useContext, useEffect, useState } from "react";
import {
  Button,
  Navbar,
  Nav,
  NavDropdown,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Dropdown, DropdownButton, Alert } from "react-bootstrap";
import Select from "react-select";
import CheckRole from "../CheckRole";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";
import AADService from "../funcionesAuth.js";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from "@mui/material";
import {AdjuntosEvaluaciones} from "../Administracion/AdjuntosPath.js"
//import AADService from "./funcionesAuth.js";

function AlertDismissibleExample({ alerta }) {
  if (alerta === 1) {
    return <Alert variant="warning">Alerta</Alert>;
  } else if (alerta === 2) {
    return <Alert variant="success">Guardó exitosamente</Alert>;
  } else if (alerta === 3) {
    return <Alert variant="danger"></Alert>;
  } else if (alerta === 4) {
    return <Alert variant="warning">Error al enviar la información</Alert>;
  } else if (alerta === 5) {
    return <Alert variant="danger">Error en el servidor</Alert>;
  } else if (alerta === 6) {
    return (
      <Alert variant="warning">
        Ya existe una evaluación para el activo seleccionado
      </Alert>
    );
  } else {
    return <p></p>;
  }
}

class Nueva_evaluacion extends Component {
  constructor(props) {
    super(props);
    //Si localStorage.getItem("variable_tipo_despliegue") contiene una ","
    let dataStorageTipoDesp = localStorage.getItem("variable_tipo_despliegue")
    let dataDespliegue = ""
    //Si dataStorageTipoDesp es null
    if (dataStorageTipoDesp == null) {
      dataDespliegue = "";
    } else if (dataStorageTipoDesp.includes(",")) {
      dataStorageTipoDesp = dataStorageTipoDesp.split(",");
      dataDespliegue = dataStorageTipoDesp.join(", ");
    } else {
      dataDespliegue = dataStorageTipoDesp;
    }
    this.state = {
      p: [0, 0, 0, 0, 0, 0, 0, 0],
      fecha: "",
      usuario: localStorage.getItem("rolusuario"),
      estado_flujo: "En Proceso",
      tipo_activo: [],
      tipo_despliegue: "",
      tipo_canal: "",
      activo_a_evaluar: [],
      analista_riesgos: [],
      OpcionesGenerales: {},
      clasificacion_confidencialidad: "",
      clasificacion_integridad: "",
      clasificacion_disponibilidad: "",
      nivel_criticidad: "",
      responsabe_negocio: "",
      idresponsable_negocio: 0,
      activo_idresponsable_negocio: 0,
      responsable_TI: "",
      idresponsable_TI: 0,
      activo_idresponsable_TI: 0,
      statusRequest: 0,
      evaluacion: [],
      idevaluacion: 0,
      triage: [],
      msj: "",
      list_triage: [],
      listtipo_activo: [],
      validated: false,
      detalle: [],
      activoEvaluado: "",
      tipoActivo: "",
      fechaEvaluacion: "",
      estado_evaluacion: 1,
      openEditModal: false,
      currentRole: null,
      showModal: false,
      fileExists: false,
      fileUrl: null,
      fileName: null,
    };
    this.AzureADService = new AADService();
    this.token = this.AzureADService.getToken();
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  handleClose() {
      this.setState({ showModal: false });
  }

  componentDidMount() {
    this.setState({
      idevaluacion: parseInt(localStorage.getItem("idevaluacion"), 10),
    });
    function llenarTipoComp(data) {
      let tipo = [];
      for (const clave in data) {
        if (
          data[clave]["codigogeneral"] == "COM" && (data[clave]["estado"] == 1)
        ) {
          tipo.push(data[clave]);
        }
      }
      return tipo;
    }
    const fetchdata = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/tipoactivo/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.token,
          },
        }
      );
      let param = await result.json();
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      this.setState({
        tipo_activo: llenarTipoComp(param),
        fecha: today.toISOString().split("T")[0],
      });
    };
    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.token,
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);
      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }
      this.setState({ OpcionesGenerales: opciones });
      this.setState({ currentRole: usuario[0].idrol });
    };
    fetchdata();
    fetchUsuario();
    const fetchdata_3 = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "usuariosroti/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.token,
          },
        }
      );
      let param = await result.json();
      this.setState({ analista_riesgos: param });
    };
    fetchdata_3();
    const fetchdata_4 = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/triage/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.token,
          },
        }
      );
      let param = await result.json();

      this.setState({ triage: param });
    };
    fetchdata_4();
    if (parseInt(localStorage.getItem("idevaluacion"), 10) === 0) {
      const opciones_tipo_activo = async () => {
        await fetchdata();
        let listtipo_activo = this.state.tipo_activo.map((tipo) => (
          <option id={tipo.idtipo_activo} key={tipo.idtipo_activo}>
            {tipo.tipo_activo}
          </option>
        ));
        this.setState({ listtipo_activo: listtipo_activo });
      };
      opciones_tipo_activo();
      const opciones_triage = async () => {
        await fetchdata_4();
        this.state.list_triage = this.state.triage.map((triage) => (
          <Row key={triage.idtriage} className="contenedor_triage">
            <Col md={6}>
              <label>{triage.pregunta}</label>
            </Col>
            <Col md={2}>
              <input
                type="radio"
                name={triage.idtriage}
                onClick={(e) => this.checkResponses(triage.idtriage, 1)}
              ></input>
            </Col>
            <Col md={2}>
              <input
                type="radio"
                name={triage.idtriage}
                onClick={(e) => this.checkResponses(triage.idtriage, 2)}
              ></input>
            </Col>
            <Col md={2}>
              <input
                type="radio"
                name={triage.idtriage}
                onClick={(e) => this.checkResponses(triage.idtriage, 0)}
                defaultChecked
              ></input>
            </Col>
          </Row>
        ));
      };
      opciones_triage();
    } else {
      const fetchUsuarioTI = async (posicion) => {
        const resultTI = await fetch(
          process.env.REACT_APP_API_URL +
            "usuariosrol/" +
            localStorage.getItem("idcompania") +
            "/"+this.state.currentRole,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.token,
            },
          }
        );

        let paramTI = await resultTI.json();
        let usuario = paramTI.filter((u) => u.idposicion == posicion);
        try {
          if (usuario[0].idposicion == localStorage.getItem("idposicion")) {
            this.setState({
              idresponsable_TI: usuario[0].idposicion,
            });
          } else {
            this.setState({
              idresponsable_TI: 0,
            });
          }
        } catch {
          this.setState({
            idresponsable_TI: 0,
          });
        }
      };
      const fetchUsuarioNeg = async (posicion) => {
        const resultNeg = await fetch(
          process.env.REACT_APP_API_URL +
            "usuariosrol/" +
            localStorage.getItem("idcompania") +
            "/"+this.state.currentRole,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.token,
            },
          }
        );
        let paramNeg = await resultNeg.json();
        let usuario = paramNeg.filter((u) => u.idposicion == posicion);
        try {
          if (usuario[0].idposicion == localStorage.getItem("idposicion")) {
            this.setState({
              idresponsable_negocio: usuario[0].idposicion,
            });
          } else {
            this.setState({
              idresponsable_negocio: 0,
            });
          }
        } catch {
          this.setState({
            idresponsable_negocio: 0,
          });
        }
      };

      const fetchDetalleEvaluacion = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/detalle/" +
            localStorage.getItem("idcompania") +
            "/" +
            localStorage.getItem("idevaluacion") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.token,
            },
          }
        );
        let param = await result.json();
        const { tipo_despliegue, tipo_canal, old_idtipo_activo } = param[0];
        tipo_despliegue
          ? localStorage.setItem("variable_tipo_despliegue", tipo_despliegue)
          : localStorage.removeItem("variable_tipo_despliegue");
        tipo_canal
          ? localStorage.setItem("variable_tipo_canal", tipo_canal)
          : localStorage.removeItem("variable_tipo_canal");
        old_idtipo_activo
          ? localStorage.setItem("variable_old_tipo_activo", old_idtipo_activo)
          : localStorage.removeItem("variable_old_tipo_activo");
        let dataCanal,
          dataDespliegue = "";
        if (!tipo_despliegue && !tipo_canal) {
          //Si dataStorageTipoDesp-canal no existe
          dataCanal = "No asociado";
          dataDespliegue = "No asociado";
        } //Si localStorage.getItem("variable_tipo_despliegue") contiene una ","
        else if (tipo_despliegue.includes(",")) {
          const Stipo_despliegue = tipo_despliegue.split(",");
          dataDespliegue = Stipo_despliegue.join(", ");
          dataCanal = tipo_canal;
        } else {
          dataDespliegue = tipo_despliegue;
          dataCanal = tipo_canal;
        }
        this.setState({
          tipo_canal: dataCanal,
          tipo_despliegue: dataDespliegue,
        });
        //TODO:

        //setDatadetalle(param);
        let datos = param[0];
        if (datos.validadorero == "TI") {
          fetchUsuarioTI(datos.responsableti);
          fetchUsuarioNeg(datos.responsablenegocio);
        } else if (datos.validadorero == "Negocio") {
          fetchUsuarioNeg(datos.responsablenegocio);
          fetchUsuarioTI(datos.responsableti);
        } else {
          fetchUsuarioTI(datos.responsableti);
          fetchUsuarioNeg(datos.responsablenegocio);
        }

        this.setState({
          estado_flujo: datos.estado_flujo,
          estado_evaluacion: datos.estado_evaluacion == true ? 1 : 0,
          detalle: datos,
          activoEvaluado: datos.activo_evaluado,
          fechaEvaluacion: datos.fecha_evaluacion,
        });
      };
      fetchDetalleEvaluacion();
      const fetchdata_5 = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/triage/" +
            localStorage.getItem("idevaluacion") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.token,
            },
          }
        );
        let param = await result.json();
        localStorage.setItem("estado_evaluacion", param.estado_evaluacion);
        this.setState({
          evaluacion: param,
        });
      };

      fetchdata_5();

      const fetchdata_6 = async () => {
        await fetchdata_5();
        localStorage.setItem(
          "variable_tipo_activo",
          this.state.evaluacion["idtipo_activo"]
        );

        const result2 = await fetch(
          process.env.REACT_APP_API_URL +
            "activoInformacion/" +
            this.state.evaluacion["idactivo"] +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.token,
            },
          }
        );
        const encryptedparam = await result2.json();
        const decryptedparam = DecryptData(encryptedparam);
        let param = JSON.parse(decryptedparam);
        this.setState({
          responsable_negocio: param.UsuarioResponsableNegocio,
          activo_idresponsable_negocio: param.idposicionresponsablenegocio,
          responsable_TI: param.UsuarioResponsableTI,
          activo_idresponsable_TI: param.idposicionresponsableti,
          clasificacion_disponibilidad: param.clasificacion_disponibilidad,
          clasificacion_confidencialidad: param.clasificacion_confidencialidad,
          clasificacion_integridad: param.clasificacion_integridad,
          nivel_criticidad: param.nivel_criticidad,
          alcance_corporativo : param.alcance_corporativo,
        });
        localStorage.setItem("alcance_corporativo", param.alcance_corporativo);
        localStorage.setItem("id_activo", this.state.evaluacion["idactivo"]);
      };
      fetchdata_6();
      const fetchdata_7 = async () => {
        //await fetchdata_6();
        const url = `${process.env.REACT_APP_API_URL}administrador/adjuntos/ERO/${localStorage.getItem("idevaluacion")}/`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': "Bearer " + this.token,
                },
            });
            if (response.ok) {
                this.setState({ fileExists: true });
                const blob = await response.blob();
                const contentDisposition = response.headers.get('Content-Disposition');
                const fileNameMatch = contentDisposition && contentDisposition.match(/filename="([^"]+)"/);
                const fileName = fileNameMatch ? fileNameMatch[1] : `ERO_${localStorage.getItem("idevaluacion")}`;
                const fileNameEncoded = encodeURIComponent(fileName);
                const downloadUrl = window.URL.createObjectURL(blob);
                this.setState({ fileUrl: downloadUrl });
                this.setState({ fileName: fileNameEncoded })
            } else {
                this.setState({ fileExists: false });
                this.setState({ fileUrl: null })
                console.error('File not found');
            }
        } catch (error) {
            console.error('Error checking file existence:', error);
        }
      };
      fetchdata_7();

      const analista_sele = async () => {
        await fetchdata_5();
        await fetchdata_3();
        let nombre_analista;
        for (let i = 0; i < this.state.analista_riesgos.length; i++) {
          if (
            this.state.analista_riesgos[i]["idusuario"] ==
            this.state.evaluacion.idanalista
          ) {
            nombre_analista = this.state.analista_riesgos[i]["nombre"];
            break;
          } else {
            nombre_analista = "Analista No Definido";
          }
        }
        this.setState({ analista_name: nombre_analista });
      };
      analista_sele();

      const opciones_triage_edit = async () => {
        await fetchdata_6();
        let list_resp = this.state.evaluacion["triage_evaluacion"];
        if (list_resp !== undefined) {
          const list_responses = list_resp.map((r) => r.respuesta);

          let input_con_respuestas,
            respuestas = [];
          input_con_respuestas = this.state.triage.map((triage) => {
            if (list_responses[triage.idtriage - 1] === "Si") {
              respuestas.push(1);
              return (
                <Row key={triage.idtriage} className="contenedor_triage">
                  <Col md={6}>
                    <label>{triage.pregunta}</label>
                  </Col>
                  <Col md={2}>
                    <input
                      type="radio"
                      name={triage.idtriage}
                      onClick={(e) => this.checkResponses(triage.idtriage, 1)}
                      defaultChecked
                    ></input>
                  </Col>
                  <Col md={2}>
                    <input
                      type="radio"
                      name={triage.idtriage}
                      onClick={(e) => this.checkResponses(triage.idtriage, 2)}
                    ></input>
                  </Col>
                  <Col md={2}>
                    <input
                      type="radio"
                      name={triage.idtriage}
                      onClick={(e) => this.checkResponses(triage.idtriage, 0)}
                    ></input>
                  </Col>
                </Row>
              );
            } else if (list_responses[triage.idtriage - 1] === "No") {
              respuestas.push(2);
              return (
                <Row key={triage.idtriage} className="contenedor_triage">
                  <Col md={6}>
                    <label>{triage.pregunta}</label>
                  </Col>
                  <Col md={2}>
                    <input
                      type="radio"
                      name={triage.idtriage}
                      onClick={(e) => this.checkResponses(triage.idtriage, 1)}
                    ></input>
                  </Col>
                  <Col md={2}>
                    <input
                      type="radio"
                      name={triage.idtriage}
                      onClick={(e) => this.checkResponses(triage.idtriage, 2)}
                      defaultChecked
                    ></input>
                  </Col>
                  <Col md={2}>
                    <input
                      type="radio"
                      name={triage.idtriage}
                      onClick={(e) => this.checkResponses(triage.idtriage, 0)}
                    ></input>
                  </Col>
                </Row>
              );
            } else {
              respuestas.push(0);
              return (
                <Row key={triage.idtriage} className="contenedor_triage">
                  <Col md={6}>
                    <label>{triage.pregunta}</label>
                  </Col>
                  <Col md={2}>
                    <input
                      type="radio"
                      name={triage.idtriage}
                      onClick={(e) => this.checkResponses(triage.idtriage, 1)}
                    ></input>
                  </Col>
                  <Col md={2}>
                    <input
                      type="radio"
                      name={triage.idtriage}
                      onClick={(e) => this.checkResponses(triage.idtriage, 2)}
                    ></input>
                  </Col>
                  <Col md={2}>
                    <input
                      type="radio"
                      name={triage.idtriage}
                      onClick={(e) => this.checkResponses(triage.idtriage, 0)}
                      defaultChecked
                    ></input>
                  </Col>
                </Row>
              );
            }
          });
          this.setState({ list_triage: input_con_respuestas, p: respuestas });
        }
      };
      opciones_triage_edit();
    }
  }
  checkResponses(index, response) {
    this.setState({
      p: this.state.p.map((value, i) => (i === index - 1 ? response : value)),
    });
  }
  renderSwitch(p1, p2, p3, p4, p5, p6, p7, p8) {
    if (p1 === 1 || p2 === 1 || p3 === 1) {
      return "Activo Crítico";
    } else if (p4 === 1 || p5 === 1 || p6 === 1 || p7 === 1 || p8 === 1) {
      return "Activo estratégico";
    } else if (
      p1 !== 1 &&
      p2 !== 1 &&
      p3 !== 1 &&
      p4 !== 1 &&
      p5 !== 1 &&
      p6 !== 1 &&
      p7 !== 1 &&
      p8 !== 1
    ) {
      return "Activo Soporte";
    }
    if (
      this.state.nivel_criticidad !== null ||
      this.state.nivel_criticidad !== "No Clasificado"
    ) {
      return this.state.nivel_criticidad;
    }
  }

  CambioEstadoFlujo() {
    //Crear variable data vacia
    var data = "";
    if (this.state.estado_flujo == "En proceso") {
      this.setState({ estado_flujo: "Pendiente Aprobación" });
      var data = JSON.stringify({
        idevaluacion: parseInt(this.state.evaluacion.idevaluacion, 10),
        estado_flujo: "Pendiente Aprobación",
      });
    }
    if (this.state.estado_flujo == "Pendiente Aprobación") {
      this.setState({ estado_flujo: "Aprobada" });
      var data = JSON.stringify({
        idevaluacion: parseInt(this.state.evaluacion.idevaluacion, 10),
        estado_flujo: "Aprobada",
      });
    }
    fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/cambioestadoflujo/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + this.token,
        },
      }
      ) 
      .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        this.setState({ statusRequest: 2 });
        localStorage.setItem("idevaluacion", data.idevaluacion);
      } else if (response.status >= 500) {
        this.setState({ statusRequest: 5 });
      } else if (response.status >= 400 && response.status < 500) {
        this.setState({ statusRequest: 4 });
      }
    });
  }

  redirect(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    let nombre_validador = document.getElementById("nombre_validador").value;
    let clasificacion_triage =
      this.state.nivel_criticidad == null ||
      this.state.nivel_criticidad == "No Clasificado"
        ? document.getElementById("clasificacion_triage").textContent
        : this.state.nivel_criticidad;
    let tipo_activo = document.getElementById("id_tipo_de_activo");
    let id = "";
    let activo = document.getElementById("activo_a_evaluar");
    let idactivo = "";

    let analista = document.getElementById("analista_riesgos");
    let idanalista = analista[analista.selectedIndex].id;
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    let respuestas_triage = [];
    let contenedor_triage =
      document.getElementsByClassName("contenedor_triage");
    for (let i = 0; i < contenedor_triage.length; i++) {
      if (contenedor_triage[i].children[1].children[0].checked === true) {
        respuestas_triage.push("Si");
      } else if (
        contenedor_triage[i].children[2].children[0].checked === true
      ) {
        //Respuesta no
        respuestas_triage.push("No");
      } else {
        //no aplica
        respuestas_triage.push("No aplica");
      }
    }
    this.setState({ validated: true });
    //Print para los datos que se envían

    if (localStorage.getItem("idevaluacion") == 0) {
      var data = JSON.stringify({
        idevaluacion: 0,
        idcompania: localStorage.getItem("idcompania"),
        fecha_evaluacion: today.toISOString().split("T")[0],
        estado_evaluacion: 1,
        estado_flujo: "En proceso",
        idtipo_activo: parseInt(tipo_activo[tipo_activo.selectedIndex].id, 10),
        idactivo: parseInt(activo[activo.selectedIndex].id, 10),
        idanalista: idanalista,
        clasificacion_por_triage: clasificacion_triage,
        fechaenvio: today.toISOString().split("T")[0],
        fechaaprobacion: "1000-01-01 00:00:00", //Revisar como se debe llenar este campo
        validadorero: nombre_validador,
        porcentajeavance: parseInt(0, 10),
        fechacreacion: today.toISOString(),
        idusuariocreacion: parseInt(localStorage.getItem("idusuario"), 10),
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: parseInt(localStorage.getItem("idusuario"), 10),
        activo_clasificado:
          this.state.nivel_criticidad == null ||
          this.state.nivel_criticidad == "No Clasificado"
            ? false
            : true,
        clasificacion_activo:
          this.state.nivel_criticidad == null ||
          this.state.nivel_criticidad == "No Clasificado"
            ? null
            : {
                clasificacion_confidencialidad:
                  this.state.clasificacion_confidencialidad,
                clasificacion_integridad: this.state.clasificacion_integridad,
                clasificacion_disponibilidad:
                  this.state.clasificacion_disponibilidad,
              },
        tipoclasificacion:
          this.state.nivel_criticidad == null ||
          this.state.nivel_criticidad == "No Clasificado"
            ? "Triage"
            : "Activo",
        triage_evaluacion:
          this.state.nivel_criticidad == null ||
          this.state.nivel_criticidad == "No Clasificado"
            ? [
                {
                  idtriage: 1,
                  idevaluacion: 0,
                  respuesta: respuestas_triage[0],
                },
                {
                  idtriage: 2,
                  idevaluacion: 0,
                  respuesta: respuestas_triage[1],
                },
                {
                  idtriage: 3,
                  idevaluacion: 0,
                  respuesta: respuestas_triage[2],
                },
                {
                  idtriage: 4,
                  idevaluacion: 0,
                  respuesta: respuestas_triage[3],
                },
                {
                  idtriage: 5,
                  idevaluacion: 0,
                  respuesta: respuestas_triage[4],
                },
                {
                  idtriage: 6,
                  idevaluacion: 0,
                  respuesta: respuestas_triage[5],
                },
                {
                  idtriage: 7,
                  idevaluacion: 0,
                  respuesta: respuestas_triage[6],
                },
                {
                  idtriage: 8,
                  idevaluacion: 0,
                  respuesta: respuestas_triage[7],
                },
              ]
            : null,
      });
      fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/triage/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then((response) =>
          response.json().then((data) => {
            if (response.status >= 200 && response.status < 300) {
              this.setState({ statusRequest: 2 });
              localStorage.setItem("idevaluacion", data.idevaluacion);
              localStorage.setItem("idactivo", parseInt(data.idactivo, 10));
              localStorage.setItem("variable_tipo_activo", id);
              const fetchDetalleEvaluacionRefresh = async (id) => {
                const result = await fetch(
                  process.env.REACT_APP_API_URL +
                    "evaluacion/detalle/" +
                    localStorage.getItem("idcompania") +
                    "/" +
                    id +
                    "/",
                  {
                    method: "GET",
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + this.token,
                    },
                  }
                );
                let param = await result.json();
                let datos = param[0];
                this.setState({
                  estado_flujo: datos.estado_flujo,
                  estado_evaluacion: datos.estado_evaluacion == true ? 1 : 0,
                  detalle: datos,
                  activoEvaluado: datos.activo_evaluado,
                  fechaEvaluacion: datos.fecha_evaluacion,
                });
              };
              fetchDetalleEvaluacionRefresh(parseInt(data.idevaluacion, 10));
              this.setState({
                idevaluacion: parseInt(data.idevaluacion, 10),
                evaluacion: data,
              });
              this.props.action();

              //document.getElementById('guardar_evaluacion').disabled=true;
            } else if (response.status >= 500) {
              this.setState({ statusRequest: 5 });
              if (
                data.non_field_errors[0] ===
                "The fields idactivo must make a unique set."
              ) {
                this.setState({ statusRequest: 6 });
              }
            } else if (response.status >= 400 && response.status < 500) {
              this.setState({ statusRequest: 4 });
            }
          })
        )
        .catch(function (err) {});
    } else {
      if (this.state.estado_evaluacion === 1) {
        localStorage.setItem("estado_evaluacion", "true");
        localStorage.setItem("idactivo", this.state.evaluacion.idactivo);
        var data = JSON.stringify({
          idevaluacion: parseInt(this.state.evaluacion.idevaluacion, 10),
          idcompania: this.state.evaluacion.idcompania,
          fecha_evaluacion: this.state.evaluacion.fecha_evaluacion,
          estado_evaluacion: this.state.estado_evaluacion,
          estado_flujo: this.state.estado_flujo,
          idtipo_activo: this.state.evaluacion.idtipo_activo,
          idactivo: this.state.evaluacion.idactivo,
          idanalista: parseInt(idanalista, 10),
          clasificacion_por_triage: clasificacion_triage,
          fechaenvio: this.state.evaluacion.fechaenvio,
          fechaaprobacion:
            this.state.evaluacion.fechaaprobacion != null
              ? this.state.evaluacion.fechaaprobacion
              : this.state.estado_flujo == "Aprobada"
              ? today.toISOString()
              : "1000-01-01 00:00:00",
          validadorero: nombre_validador,
          porcentajeavance: parseInt(0, 10),
          fechacreacion: this.state.evaluacion.fechacreacion,
          idusuariocreacion: this.state.evaluacion.idusuariocreacion,
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: parseInt(
            localStorage.getItem("idusuario"),
            10
          ),
          activo_clasificado:
            this.state.nivel_criticidad == null ||
            this.state.nivel_criticidad == "No Clasificado"
              ? false
              : true,
          clasificacion_activo: {
            clasificacion_confidencialidad: "Bajo",
            clasificacion_integridad: "Alto",
            clasificacion_disponibilidad: "Alto",
          },
          triage_evaluacion:
            this.state.nivel_criticidad == null ||
            this.state.nivel_criticidad == "No Clasificado"
              ? [
                  {
                    idtriage: parseInt(
                      this.state.evaluacion.triage_evaluacion[0].idtriage,
                      10
                    ),
                    idtriageevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[0]
                        .idtriageevaluacion,
                      10
                    ),
                    idevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[0].idevaluacion,
                      10
                    ),
                    respuesta: respuestas_triage[0],
                  },
                  {
                    idtriage: parseInt(
                      this.state.evaluacion.triage_evaluacion[1].idtriage,
                      10
                    ),
                    idtriageevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[1]
                        .idtriageevaluacion,
                      10
                    ),
                    idevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[1].idevaluacion,
                      10
                    ),
                    respuesta: respuestas_triage[1],
                  },
                  {
                    idtriage: parseInt(
                      this.state.evaluacion.triage_evaluacion[2].idtriage,
                      10
                    ),
                    idtriageevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[2]
                        .idtriageevaluacion,
                      10
                    ),
                    idevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[2].idevaluacion,
                      10
                    ),
                    respuesta: respuestas_triage[2],
                  },
                  {
                    idtriage: parseInt(
                      this.state.evaluacion.triage_evaluacion[3].idtriage,
                      10
                    ),
                    idtriageevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[3]
                        .idtriageevaluacion,
                      10
                    ),
                    idevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[3].idevaluacion,
                      10
                    ),
                    respuesta: respuestas_triage[3],
                  },
                  {
                    idtriage: parseInt(
                      this.state.evaluacion.triage_evaluacion[4].idtriage,
                      10
                    ),
                    idtriageevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[4]
                        .idtriageevaluacion,
                      10
                    ),
                    idevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[4].idevaluacion,
                      10
                    ),
                    respuesta: respuestas_triage[4],
                  },
                  {
                    idtriage: parseInt(
                      this.state.evaluacion.triage_evaluacion[5].idtriage,
                      10
                    ),
                    idtriageevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[5]
                        .idtriageevaluacion,
                      10
                    ),
                    idevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[5].idevaluacion,
                      10
                    ),
                    respuesta: respuestas_triage[5],
                  },
                  {
                    idtriage: parseInt(
                      this.state.evaluacion.triage_evaluacion[6].idtriage,
                      10
                    ),
                    idtriageevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[6]
                        .idtriageevaluacion,
                      10
                    ),
                    idevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[6].idevaluacion,
                      10
                    ),
                    respuesta: respuestas_triage[6],
                  },
                  {
                    idtriage: parseInt(
                      this.state.evaluacion.triage_evaluacion[7].idtriage,
                      10
                    ),
                    idtriageevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[7]
                        .idtriageevaluacion,
                      10
                    ),
                    idevaluacion: parseInt(
                      this.state.evaluacion.triage_evaluacion[7].idevaluacion,
                      10
                    ),
                    respuesta: respuestas_triage[7],
                  },
                ]
              : null,
        });
        fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/triage/" +
            localStorage.getItem("idevaluacion") +
            "/",
          {
            method: "PUT",
            body: data,
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: "Bearer " + this.token,
            },
          }
        )
          .then((response) =>
            response.json().then((data) => {
              if (response.status >= 200 && response.status < 300) {
                this.setState({ statusRequest: 2, evaluacion: data });
                localStorage.setItem("idevaluacion", data.idevaluacion);
                //localStorage.setItem('variable_tipo_activo', id);
                //document.getElementById('guardar_evaluacion').disabled=true;
              } else if (response.status >= 500) {
                this.setState({ statusRequest: 5 });
              } else if (response.status >= 400 && response.status < 500) {
                this.setState({ statusRequest: 4 });
              }
            })
          )
          .catch(function (err) {});
      }

      if (this.state.estado_evaluacion === 0) {
        this.setState({ openEditModal: true });
      }
    }
    //return <Redirect to="/detalle-evaluacion" />;
    //return <Link to = "Evaluaciones"/>
  }
  change_prov() {
    let tipo_activo = document.getElementById("id_tipo_de_activo");
    let id = tipo_activo[tipo_activo.selectedIndex].id;
    const fetchdata = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "activosTipo/" +
          id +
          "/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.token,
          },
        }
      );
      let param = await result.json();
      if (id == 10) {
        let compPrin = param.filter(
          (compo) => compo.componente_principal == true
        );
        this.setState({ activo_a_evaluar: compPrin });
      } else {
        this.setState({ activo_a_evaluar: param });
      }
    };
    fetchdata();
  }
  change_activo() {
    let tipo_activo = document.getElementById("activo_a_evaluar");
    let id = tipo_activo[tipo_activo.selectedIndex].id;

    const fetchdata = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "activoInformacion/" + id + "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.token,
          },
        }
      );
      const encryptedparam = await result.json();
      const decryptedparam = DecryptData(encryptedparam);
      let param = JSON.parse(decryptedparam);
      this.setState({
        responsable_negocio: param.UsuarioResponsableNegocio,
        responsable_TI: param.UsuarioResponsableTI,
        clasificacion_disponibilidad: param.clasificacion_disponibilidad,
        clasificacion_confidencialidad: param.clasificacion_confidencialidad,
        clasificacion_integridad: param.clasificacion_integridad,
        nivel_criticidad: param.nivel_criticidad,
        alcance_corporativo : param.alcance_corporativo,
        tipo_despliegue: param.tipo_despliegue,
        tipo_canal: param.tipo_canal,
      });
    };
    fetchdata();
  }

  cancel_eval() {
    localStorage.setItem("idevaluacion", 0);
    this.props.action();
  }

  render() {
    const check = new CheckRole("/detalle-evaluacion");
    let listactivo_a_evaluar = this.state.activo_a_evaluar.map((activo) => (
      <option id={activo.idactivo} key={activo.idactivo}>
        {activo.nombre}
      </option>
    ));

    let listanalista_riesgos = this.state.analista_riesgos.map((activo) => (
      <option id={activo.idusuario} key={activo.idusuario}>
        {activo.nombre}
      </option>
    ));
    let opciones = ["TI", "Negocio", "Mixto"];
    let list_ero = [];
    if (this.state.evaluacion.validadorero) {
      list_ero = [
        <option defaultValue selected>
          {this.state.evaluacion.validadorero}
        </option>,
      ].concat(
        opciones.map((opc) => {
          if (opc !== this.state.evaluacion.validadorero)
            return <option>{opc}</option>;
        })
      );
    } else {
      list_ero = [].concat(
        opciones.map((opc) => {
          return <option>{opc}</option>;
        })
      );
    }

    if (
      parseInt(localStorage.getItem("idevaluacion"), 10) !== 0 &&
      this.state.evaluacion.length !== 0
    ) {
      listactivo_a_evaluar = this.state.activo_a_evaluar.map((activo) => {
        if (this.state.evaluacion["idactivo"] === activo.idactivo) {
          return (
            <option id={activo.idactivo} key={activo.idactivo} selected>
              {activo.nombre}
            </option>
          );
        }
      });

      listanalista_riesgos = [
        <option
          id={this.state.evaluacion.idanalista}
          defaultValue
          key={this.state.evaluacion.idanalista}
          selected
        >
          {this.state.analista_name}
        </option>,
      ].concat(
        this.state.analista_riesgos.map((activo) => {
          if (activo.idusuario !== this.state.evaluacion.idanalista)
            return (
              <option id={activo.idusuario} key={activo.idusuario}>
                {activo.nombre}
              </option>
            );
        })
      );
    }
    const changeEvaluationStatus = () => {
      let nombre_validador = document.getElementById("nombre_validador").value;
      let clasificacion_triage =
        this.state.nivel_criticidad == null ||
        this.state.nivel_criticidad == "No Clasificado"
          ? document.getElementById("clasificacion_triage").textContent
          : this.state.nivel_criticidad;

      let analista = document.getElementById("analista_riesgos");
      let idanalista = analista[analista.selectedIndex].id;
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let respuestas_triage = [];
      let contenedor_triage =
        document.getElementsByClassName("contenedor_triage");
      for (let i = 0; i < contenedor_triage.length; i++) {
        if (contenedor_triage[i].children[1].children[0].checked === true) {
          respuestas_triage.push("Si");
        } else if (
          contenedor_triage[i].children[2].children[0].checked === true
        ) {
          //Respuesta no
          respuestas_triage.push("No");
        } else {
          //no aplica
          respuestas_triage.push("No aplica");
        }
      }
      this.setState({ validated: true });

      const dataToSend = JSON.stringify({
        idevaluacion: parseInt(this.state.evaluacion.idevaluacion, 10),
        idcompania: this.state.evaluacion.idcompania,
        fecha_evaluacion: this.state.evaluacion.fecha_evaluacion,
        estado_evaluacion: false,
        estado_flujo: this.state.estado_flujo,
        idtipo_activo: this.state.evaluacion.idtipo_activo,
        idactivo: this.state.evaluacion.idactivo,
        idanalista: parseInt(idanalista, 10),
        clasificacion_por_triage: clasificacion_triage,
        fechaenvio: this.state.evaluacion.fechaenvio,
        fechaaprobacion:
          this.state.evaluacion.fechaaprobacion != null
            ? this.state.evaluacion.fechaaprobacion
            : this.state.estado_flujo == "Aprobada"
            ? today.toISOString()
            : "1000-01-01 00:00:00",
        validadorero: nombre_validador,
        porcentajeavance: parseInt(0, 10),
        fechacreacion: this.state.evaluacion.fechacreacion,
        idusuariocreacion: this.state.evaluacion.idusuariocreacion,
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: parseInt(localStorage.getItem("idusuario"), 10),
        activo_clasificado:
          this.state.nivel_criticidad == null ||
          this.state.nivel_criticidad == "No Clasificado"
            ? false
            : true,
        clasificacion_activo: {
          clasificacion_confidencialidad: "Bajo",
          clasificacion_integridad: "Alto",
          clasificacion_disponibilidad: "Alto",
        },
        triage_evaluacion:
          this.state.nivel_criticidad == null ||
          this.state.nivel_criticidad == "No Clasificado"
            ? [
                {
                  idtriage: parseInt(
                    this.state.evaluacion.triage_evaluacion[0].idtriage,
                    10
                  ),
                  idtriageevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[0]
                      .idtriageevaluacion,
                    10
                  ),
                  idevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[0].idevaluacion,
                    10
                  ),
                  respuesta: respuestas_triage[0],
                },
                {
                  idtriage: parseInt(
                    this.state.evaluacion.triage_evaluacion[1].idtriage,
                    10
                  ),
                  idtriageevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[1]
                      .idtriageevaluacion,
                    10
                  ),
                  idevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[1].idevaluacion,
                    10
                  ),
                  respuesta: respuestas_triage[1],
                },
                {
                  idtriage: parseInt(
                    this.state.evaluacion.triage_evaluacion[2].idtriage,
                    10
                  ),
                  idtriageevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[2]
                      .idtriageevaluacion,
                    10
                  ),
                  idevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[2].idevaluacion,
                    10
                  ),
                  respuesta: respuestas_triage[2],
                },
                {
                  idtriage: parseInt(
                    this.state.evaluacion.triage_evaluacion[3].idtriage,
                    10
                  ),
                  idtriageevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[3]
                      .idtriageevaluacion,
                    10
                  ),
                  idevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[3].idevaluacion,
                    10
                  ),
                  respuesta: respuestas_triage[3],
                },
                {
                  idtriage: parseInt(
                    this.state.evaluacion.triage_evaluacion[4].idtriage,
                    10
                  ),
                  idtriageevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[4]
                      .idtriageevaluacion,
                    10
                  ),
                  idevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[4].idevaluacion,
                    10
                  ),
                  respuesta: respuestas_triage[4],
                },
                {
                  idtriage: parseInt(
                    this.state.evaluacion.triage_evaluacion[5].idtriage,
                    10
                  ),
                  idtriageevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[5]
                      .idtriageevaluacion,
                    10
                  ),
                  idevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[5].idevaluacion,
                    10
                  ),
                  respuesta: respuestas_triage[5],
                },
                {
                  idtriage: parseInt(
                    this.state.evaluacion.triage_evaluacion[6].idtriage,
                    10
                  ),
                  idtriageevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[6]
                      .idtriageevaluacion,
                    10
                  ),
                  idevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[6].idevaluacion,
                    10
                  ),
                  respuesta: respuestas_triage[6],
                },
                {
                  idtriage: parseInt(
                    this.state.evaluacion.triage_evaluacion[7].idtriage,
                    10
                  ),
                  idtriageevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[7]
                      .idtriageevaluacion,
                    10
                  ),
                  idevaluacion: parseInt(
                    this.state.evaluacion.triage_evaluacion[7].idevaluacion,
                    10
                  ),
                  respuesta: respuestas_triage[7],
                },
              ]
            : null,
      });

      fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/triage/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "PUT",
          body: dataToSend,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then((response) =>
          response.json().then((data) => {
            if (response.status >= 200 && response.status < 300) {
              this.setState({ statusRequest: 2, evaluacion: data });
              localStorage.setItem("idevaluacion", data.idevaluacion);
            } else if (response.status >= 500) {
              this.setState({ statusRequest: 5 });
            } else if (response.status >= 400 && response.status < 500) {
              this.setState({ statusRequest: 4 });
            }
          })
        )
        .catch(function (err) {});
    };

    return (
      <div>
        <AlertDismissibleExample alerta={this.state.statusRequest} />
        <AdjuntosEvaluaciones 
          show={this.state.showModal} 
          onHide={this.handleClose}
          fileExists={this.state.fileExists}
          elemento={"ERO"}
          idElemento={this.state.idevaluacion}
        />
        <Form
          id="formData"
          onSubmit={(e) => this.redirect(e)}
          noValidate
          validated={this.state.validated}
        >
          <Row className="row_n_eval">
            <Col md={6}>
              <h2 className="estilo_titulos">Evaluación de riesgos</h2>
            </Col>
            <Col md={2} />
            <Col md={4}>
              {this.state.idevaluacion != 0 ? (
                <>
                  {this.state.usuario == 2 &&
                  this.state.estado_flujo == "En proceso" ? (
                    <Button
                      className="botonPositivo"
                      onClick={() => {
                        this.setState({ estado_flujo: "Pendiente Aprobación" });
                        this.CambioEstadoFlujo();
                      }}
                    >
                      Solicitar Validación
                    </Button>
                  ) : (
                    <>
                      {this.state.usuario == 2 &&
                      this.state.estado_flujo == "Aprobada" ? (
                        <Button
                          className="botonPositivo"
                          onClick={() => {
                            this.setState({ estado_flujo: "En proceso" });
                          }}
                        >
                          Evaluar de nuevo
                        </Button>
                      ) : (
                        <>
                          {(this.state.idresponsable_negocio ==
                            this.state.activo_idresponsable_negocio ||
                            this.state.idresponsable_TI ==
                              this.state.activo_idresponsable_TI) &&
                          this.state.estado_flujo == "Pendiente Aprobación" ? (
                            <Button
                              className="botonPositivo"
                              onClick={() => {
                                this.setState({ estado_flujo: "Aprobada" });
                                this.CambioEstadoFlujo();
                              }}
                            >
                              Validar Evaluación
                            </Button>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <hr />
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Id Evaluación</label>
            </Col>
            <Col md={3}>
              <label className="texto">{this.state.idevaluacion}</label>
            </Col>
            <Col md={3}>
              <label className="label">Fecha Evaluación</label>
            </Col>
            <Col md={3}>
              <label className="texto">{this.state.fechaEvaluacion}</label>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Estado de la Evaluación</label>
            </Col>

            <Col md={3} className="d-flex">
              <Col>
                <label className="texto">
                  {this.state.estado_evaluacion === 1 ? "Activa" : "Inactivo"}
                </label>
              </Col>
              <Col>
                {(this.state.currentRole === 2 ||
                  this.state.currentRole === 1) &&
                  this.state.estado_evaluacion === 0 &&
                  parseInt(localStorage.getItem("idevaluacion")) !== 0 && (
                    <Button
                      id="guardar_evaluacion"
                      type="submit"
                      style={{
                        backgroundColor: "#00c389 ",
                        borderColor: "#00c389",
                      }}
                      onClick={() => {
                        if (this.state.estado_evaluacion === 0) {
                          this.setState({ estado_evaluacion: 1 });
                        }
                      }}
                    >
                      {"Activar"}
                    </Button>
                  )}
                {(this.state.currentRole === 2 ||
                  this.state.currentRole === 1) &&
                  this.state.estado_evaluacion === 1 &&
                  parseInt(localStorage.getItem("idevaluacion")) !== 0 && (
                    <Button
                      id="guardar_evaluacion"
                      type="submit"
                      style={{
                        backgroundColor: "#00c389 ",
                        borderColor: "#00c389",
                      }}
                      onClick={() => {
                        if (this.state.estado_evaluacion === 1) {
                          this.setState({ estado_evaluacion: 0 });
                          localStorage.setItem("estado_evaluacion", "false");
                          changeEvaluationStatus();
                        }
                      }}
                    >
                      {"Desactivar"}
                    </Button>
                  )}
              </Col>
            </Col>
            <Col md={3}>
              <label className="label">Estado del flujo</label>
            </Col>
            <Col md={3}>
              <label className="texto">
                {this.state.estado_flujo === "Pendiente Aprobación"
                  ? "Pendiente Validación"
                  : this.state.estado_flujo === "Aprobada"
                  ? "Validada"
                  : "En Proceso"}
              </label>
            </Col>
          </Row>
          <Row
            className="row_n_eval"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Col md={3}>
              <label className="label">Tipo de activo a evaluar</label>
            </Col>
            <Col md={3}>
              {this.state.idevaluacion === 0 ? (
                <>
                  <select
                    className="form-control"
                    title="Tipo de activo"
                    id="id_tipo_de_activo"
                    required
                    onChange={() => this.change_prov()}
                  >
                    <option id={0} key={0} value="">
                      --Seleccione el tipo de activo--
                    </option>
                    {this.state.listtipo_activo}
                  </select>
                  <Form.Control.Feedback type="invalid">
                    Por favor seleccione un tipo de activo.
                  </Form.Control.Feedback>
                </>
              ) : (
                <label className="texto">
                  {this.state.detalle.tipo_activo}
                </label>
              )}
            </Col>
            <Col md={3}>
              <label className="label">Clasificación del activo</label>
            </Col>
            <Col md={3} className="clasicenter">
              <label id="clasificacion_triage" className="texto">
                {this.renderSwitch(
                  this.state.p[0],
                  this.state.p[1],
                  this.state.p[2],
                  this.state.p[3],
                  this.state.p[4],
                  this.state.p[5],
                  this.state.p[6],
                  this.state.p[7]
                )}
              </label>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Activo a evaluar</label>
            </Col>
            <Col md={3}>
              {this.state.idevaluacion === 0 ? (
                <>
                  <select
                    className="form-control"
                    title="Activo a evaluar"
                    id="activo_a_evaluar"
                    required
                    onChange={() => this.change_activo()}
                  >
                    <option id={0} key={0} value="">
                      --Seleccione el activo a evaluar--
                    </option>
                    {listactivo_a_evaluar}
                  </select>
                  <Form.Control.Feedback type="invalid">
                    Por favor seleccione un activo.
                  </Form.Control.Feedback>
                </>
              ) : (
                <>
                  <label className="texto">{this.state.activoEvaluado}</label>
                </>
              )}
            </Col>
            <Col md={2}>
              <label className="label">Evidencia de validación</label>
            </Col>
            <Col md={1}>
              <Button
               onClick={this.handleShow}
               disabled={(this.state.currentRole !== 1 && this.state.currentRole !== 2) || (this.state.estado_flujo !== "Pendiente Aprobación")}
              >
                Cargar
              </Button>
            </Col>
            <Col md={3}>
              {this.state.fileUrl && (
                  <a href={this.state.fileUrl} download={this.state.fileName}>
                      {decodeURIComponent(this.state.fileName)}
                  </a>
              )}
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Tipo de Despliegue</label>
            </Col>
            <Col md={9}>
              <label className="texto">{this.state.tipo_despliegue}</label>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Tipo de Canal</label>
            </Col>
            <Col md={9}>
              <label className="texto">{this.state.tipo_canal}</label>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Analista de riesgos</label>
            </Col>
            <Col md={3}>
              <select
                className="form-control"
                title="analista riesgos"
                id="analista_riesgos"
              >
                {listanalista_riesgos}
              </select>
            </Col>
            <Col md={3}>
              <label className="label">Validador ERO</label>
            </Col>
            <Col md={3}>
              <select
                className="form-control"
                title="Tipo de activo"
                id="nombre_validador"
              >
                {list_ero}
              </select>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Responsable del Negocio</label>
            </Col>
            <Col md={3}>
              <label id="responsable_negocio" className="texto">
                {this.state.responsable_negocio}
              </label>
            </Col>
            <Col md={3}>
              <label className="label">Responsable en TI</label>
            </Col>
            <Col md={3}>
              <label id="responsable_TI" className="texto">
                {this.state.responsable_TI}
              </label>
            </Col>
          </Row>
          {this.state.alcance_corporativo == "1" ? (
            <Row className='mb'>
            <Col className='text-center'>
              <p className='label forn-label' style={{ color: 'red' }}>
                El activo a evaluar es de alcance corporativo, recuerde socializar con los equipos ROTIC de los países
                </p>
              </Col>
            </Row>
            ) : (
              null
            ) }
          {this.state.nivel_criticidad == null ||
          this.state.nivel_criticidad == "No Clasificado" ? (
            <div>
              <Row>
                <Col md={8}>
                  <h2 className="estilo_titulos">
                    Triage del activo a evaluar
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col md={6}></Col>
                <Col md={2}>
                  <label>Si</label>
                </Col>
                <Col md={2}>
                  <label>No</label>
                </Col>
                <Col md={2}>
                  <label>No aplica</label>
                </Col>
              </Row>
              {this.state.list_triage}
              <hr />
            </div>
          ) : null}
          {check.checkPermits(this.state.OpcionesGenerales, "PUT") ? (
            <Row className="clasicenter" style={{ marginTop: "4%" }}>
              <Col md={3}></Col>
              <Col md={3}>
              {this.state.currentRole !== 3 && this.state.currentRole !== 4 && this.state.currentRole !== 5 ? (
                  <Button
                  className='botonPositivo'
                  id='guardar_evaluacion'
                  type='submit'
                    style={{ width: "100%" }}
                  >
                    Guardar
                  </Button>
                ) : null}
              </Col>
              <Col md={3}>
              {this.state.currentRole !== 3 && this.state.currentRole !== 4 && this.state.currentRole !== 5 ? (
                <Link to='evaluaciones'>
                    <Button
                    className='botonNegativo'
                      onClick={() => this.cancel_eval()}
                      style={{ width: "100%" }}
                    >
                      {" "}
                      Cancelar{" "}
                    </Button>{" "}
                  </Link>
                ) : null}
              </Col>
              <Col md={3}></Col>
              {this.state.openEditModal && (
                <Dialog
                  open={this.state.openEditModal}
                  sx={{ borderRadius: "10px" }}
                >
                  <DialogTitle>La evaluación esta desactivada</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Si desea editar debe proceder a activar la evaluacion
                    </DialogContentText>
                  </DialogContent>
                  <Box display="flex" justifyContent="center">
                    <DialogActions>
                      <Button
                        className="botonGeneral"
                        onClick={() => this.setState({ openEditModal: false })}
                      >
                        Entendido
                      </Button>
                    </DialogActions>
                  </Box>
                </Dialog>
              )}
            </Row>
          ) : (
            ""
          )}
        </Form>
      </div>
    );
  }
}

export default Nueva_evaluacion;
